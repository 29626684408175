@use "../../styles/colors" as colors;

.search-bar-animation {
    height: 33px;
    display: flex;
    position: relative;
    align-items: center;
    padding-right: 31px;
    // border-right: 1px solid colors.$gray-1;

    &.search-close {
        .search-input {
            padding: 0;
            opacity: 0;
            width: 35px;
        }

        .search-icon {
            right: 31px;
            height: 20px;
            padding: 8px;
            fill: colors.$gray-2;
            box-sizing: content-box;
            transition: all 0.2s ease-in;
            transform: translate(0, -50%);

            svg {
                height: 20px;
                font-size: 20px;

                path {
                    fill: colors.$gray-2;
                }
            }
        }
    }

    .search-icon {
        top: 50%;
        right: 41px;
        position: absolute;
        color: colors.$gray-2;
        transition: all .2s ease-out;
        transform: translate(-50%, -50%);
    }

    .search-input {
        height: 33px;
        width: 312px;
        border-radius: 16px;
        padding: 0 36px 0 14px;
        background-color: colors.$gray-8;
        transition: all 0.2s ease-in-out;
    }
}