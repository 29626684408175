.change-password-modal {
  .change-password-validation {
    width: 100%;
    display: flex;
    justify-content: center;

    .checked {
      text-decoration: line-through;
    }
  }
}
