@use "../../common/styles/colors" as colors;

.home-content {
  margin: 0 67px 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .home-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1090px;
    width: 100%;
    color: #000;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left !important;
    margin-bottom: 24px;
  }

  .content-box {
    // display: flex;
    // flex-direction: column;
    // justify-content: start;
    max-width: 1090px;

    .top-row {
      max-width: 1090px;

      .create-propozall-box {
        .home-container {
          max-width: 167px;
          height: 367px;
          padding: 19px 21px;

          align-items: center;
          text-align: center;
          border: 1px solid var(--color-neutral-light, #e0e0e0);
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .normal-18 {
            line-height: normal !important;
            font-style: normal !important;
          }

          .normal-12 {
            line-height: normal !important;
            font-style: normal !important;
            font-weight: 400 !important;
            color: colors.$blue-4;
            text-align: start !important;
          }

          .bottom-info {
              min-height: 90px;
          }

          &.clickable {
            cursor: pointer;
            &:hover {
              background-color: colors.$blue-4;

              .container-icons-fill {
                svg {
                  path {
                    fill: white !important;
                    fill-rule: evenodd;
                  }
                }
              }
              .container-icons-stroke {
                svg {
                  path {
                    stroke: white !important;
                  }
                }
              }

              .normal-12 {
                color: #fff;
              }

              .normal-18 {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .col-home-img {
      text-align: center;
      
      .home-img {
        width: auto;
        height: 367px;
      }
    }

    .bottom-row {
      max-width: 1090px;
      width: 100%;

      .col-invoice-progress {
        padding-left: 0 !important;
      }

      .col-newsletter-progress {
        padding-right: 0 !important;
      }
    }
  }
}
