@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.file-upload-box {
  .ant-row.label {
    @extend .normal-12-500;
    color: var(--primary-blue);
    line-height: 28px;
  }

  .ant-row.icon {
    padding: 0px 30px;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: colors.$gray-2;
      }
    }
  }
}

.ant-upload-list-item-name {
  color: var(--primary-blue);
}

.ant-upload-list-item-card-actions {
  .delete-icon {
    svg {
      width: 23px;
      height: 23px;

      path {
        fill: colors.$gray-2;
      }
    }
  }
}
