@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.app-radio-button {
  .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-blue) !important;
  }

  .ant-radio-checked::after {
    border: 1px solid var(--primary-blue);
  }

  .ant-radio-inner::after {
    background-color: var(--primary-blue) !important;
  }

  &:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--primary-blue) !important;
  }
}
