@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

// .button-add-absolut {
//     position: absolute;
//     left: calc(100% + 52px);
//     transform: translateY(-100%);
//     height: 100%;
// }

.row-select-cnpj-proposal {
    // padding-bottom: 20px;

    .button-add-option-on-select {
        margin: 0 0 5px;
    }

    .anticon-heart.favorite-icon {
        margin-top: -1px;
        padding-left: 8px;
    }

    .text-value {
        height: 40px;
        display: flex;
        font-size: 16px;
        margin-top: 8px;
    }

    .select-cnpj-proposal {
        .ant-select-selection-item {
            line-height: 40px !important;

            > div {
                line-height: 40px !important;

                > div  {
                    display: inline;
                }

                > div:first-child::after {
                    content: " | ";
                }
            }
        }
    }
}

.select-cnpj-option {
    &:not(:last-child) {
        border-bottom: 1px solid rgba(128, 128, 128, 0.086);
    }

    > div {
        line-height: 25px;
    }
}
