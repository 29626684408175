@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";
.benefits-page {
    width: 100%;
    height: 767px;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1106px;
    padding:  60px 50px;
    
    background-color: colors.$white-2;
    border-radius: 8px; 
    border: 1px #D9D9D9 solid;

    .page-title {
        // @extend .normal-18-bold;
        font-family: Poppins;
        font-weight: 500;
        line-height: 18px;
        font-size: 18px;
        margin-bottom: 18px;
    }

    .page-description {
        font-family: Poppins;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 48px;
    }

    // .page-congratulations {
    //     @extend .normal-18-bold;
    //     line-height: 18px;
    //     margin-bottom: 40px;
    // }

    .benefit-card {
        border-radius: 8px;
        background-color: colors.$gray-3;
        border: 1px solid colors.$gray-8;
        padding: 16px;
        max-height: 450px;

        .explain-content {
            @extend .normal-12;
            text-align: left;
            width: 100%;
            min-height: 310px;
            border-radius: 8px;
            margin-bottom: 6px;
            line-height: 18px;

            img {
                margin: 8px 0;
                height: 68px;
            }

            .benefits-list {
                margin-top: 18px;
            }

            > .ant-col:first-child {
                text-align: center;
            }
        }

        // &#payoneer {
        //     img {
        //         margin: 15px 0;
        //     }
        // }

        .action-content {
            width: 100%;
            // min-height: 80px;
            border-radius: 8px;
            
            .blue-button {
                width: 100%;
                height: 48px !important;
            }

            > .ant-col {
                display: flex;
                justify-content: center;
            }
        }
    }  
}

