@use "../../../../common/styles/colors"as colors;
@use "../../../../common/styles/app.scss";

$mobile-screen: 768px;
$modal-max-height: 610px;

.box-user-modal {
    $border-color: colors.$gray-7;
    $background-suitcase-icon: colors.$blue-7;
    $role-color: var(--primary-blue);
    $interprise-color: colors.$blue-4;

    display: flex;
    position: fixed;
    z-index: 1000;
    top: 60px;
    left: 61%;
    overflow: hidden;
   
    border-radius: 8px;
    width: 408px;
    padding: 24px;

    &[data-show-role-active="true"] {
        //
    }


    @media (max-height: $modal-max-height) {
        overflow: auto;
        top: 0;
        height: 100vh;
        padding: 0;
    }
    

    @media (max-width: $mobile-screen) {
        left: 41%;
    }


    @keyframes animation-user-box {
        from {
            height: 0;
            padding: 0;
        }

        to {
            height: 578px;
            padding: auto;
        }
    }

    @keyframes appear-content-user-box {
        from {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .box-user-content {
        cursor: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 408px;
        background-color: white;
        z-index: 1001;
        border-radius: 8px;
        padding: 18px;
        animation: animation-user-box 0.6s;

        @media (max-height: $modal-max-height) {
            overflow: auto;
            height: 100vh;
            height: $modal-max-height;
        }

        >* {
            animation: appear-content-plan-use-box 0.6s;
        }

        .ant-divider {
            margin: 18px 0 !important;
        }

    }

    .back-modal {
        overflow: auto;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 64px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        animation-duration: 0.5s;
        animation-name: animate-fade;
        animation-fill-mode: both;

        @media (max-height: $modal-max-height) {
            overflow: auto;
            height: 100%;
            top: 0;
        }
    }

    .role-icon {
        span {
            width: 32px;
            height: 32px;
            background-color: $role-color;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            padding-top: 2px;
        }

        .suitcase-icon {
            background-color: $background-suitcase-icon;
        }

        svg {
            width: 25px;
            height: 25px;
        }
    }

    .box-role-style {
        max-height: 70px;
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 8px;
        margin-top: 20px;
        padding: 13px;
        cursor: pointer;

        &[data-active="true"] {
            border-radius: 8px 8px 0 0;
        }






        .arrow-role-icon {

            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .box-role-options {
        max-height: 70px;
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 0 0 8px 8px;
        padding: 13px;
        margin-top: -1px;
        cursor: pointer;


    }

    .box-role-options,
    .box-role-style,
    .box-config-style,
    .exit-box {
        &:hover {
            background-color: rgba(217, 225, 248, 1);
            border-color: rgba(140, 165, 234, 1);
            .role-icon{
                border-radius: 100%;
                border: 1px solid rgba(140, 165, 234, 1);
                margin: -1px;
            }
    

        }

        &:active {
            background-color: rgba(191, 205, 243, 1);
            border-color: rgba(140, 165, 234, 1);
        }
    }

    .box-config-style {
        max-height: 70px;
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 8px;
        margin-top: 20px;
        padding: 19px;
        cursor: pointer;
    }

    .plan-use-box {
        width: 100%;
        border-radius: 8px;
        padding: 24px;
        margin-top: 20px;
        cursor: auto;
    }

    .plan-use-usage {
        @extend .normal-12-500;
        color: colors.$blue-6;
        margin-right: 5px;
        cursor: auto;
    }

    .plan-use-usage-description {
        @extend .normal-12;
        color: black;
        cursor: auto;
    }

    .ant-divider.divider-style {
        margin: 24px 0px 24px 0px !important;
    }

    .exit-box {
        max-height: 70px;
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 8px;
        padding: 13px;
        cursor: pointer;

        .exit-icon {
            height: 24px;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .box-plan-upgrade {
        *:hover {
            color: colors.$blue-3 !important;

            svg {
                stroke: colors.$blue-3 !important;

                path {
                    fill: colors.$blue-3 !important;
                }
            }
        }
    }



}