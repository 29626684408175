@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.info-date-time {
  @extend .normal-14;
  color: colors.$gray-0;
  font-weight: 500;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    position: relative;
    top: 3px;
    opacity: 0.8;
    path {
      stroke: colors.$gray-0;
    }
  }
}
