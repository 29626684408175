@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.ant-form-item-has-error {
    .flatted-input {
        border-bottom: 1px solid colors.$red-2 !important;
    }
}

.flatted-input {
    @extend .normal-16;
    border: none !important;
    border-bottom: 1px solid colors.$gray-1 !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    outline: none !important;
    color: colors.$gray-0 !important;
    height: 40px !important;

    &:disabled {
        color: colors.$gray-7 !important;
        cursor: not-allowed;
    }

    &[readonly] {
        background-color: white !important;
        cursor: auto;
    }

    &[data-editable="false"] {
        // background-color: colors.$gray-3 !important;
        cursor: not-allowed;
        border-bottom: none !important;
    }
    
    &::placeholder {
        font-size: 16px;
        color: colors.$gray-1 !important;
    }

    &:focus,
    &:focus-visible {
        box-shadow: none !important;
        outline: none !important;
    }
}

.flatted-textarea {
    @extend .flatted-input;

    height: 226px !important;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid colors.$gray-1 !important;
    border-radius: 5px;
}


.flatted-dropdown-input {
    .flatted-input {
        width: 100%;
        position: relative;
    }

    .dropdown-icon {
        position: absolute;
        right: 12px;
        line-height: 30px;
        color: colors.$gray-2;

        svg {
            height: 11px;
            width: 11px;
        }
    }
}

.flatted-dropdown-input-container.with-cleaner {
    display: flex;
    flex-direction: row;

    .flatted-dropdown-input {
        min-width: 100%;
    }
}

.flatted-dropdown-input + .dropdown-cleaner {
    background-color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: relative;
    left: -45px;
    top: 12px;

    svg {
        width: 13px;
        height: auto;
        color: rgba(0,0,0,.25);
    }
}


.flatted-label-input {
    display: flex;
    align-items: center;

    .flatted-input {
        width: 100%;
        position: relative;
        z-index: 900;
        text-align: right;
    }

    label {
        @extend .normal-14-500;
        position: absolute;
        z-index: 901;
    }
}

.ant-col .ant-select-selector {
    width: 100%;
}

.flatted-phone-input {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .flatted-masked-input,
    .flatted-input {
        // max-width: 72%;
        top: 0 !important;
        position: relative;
        padding: 0 !important;
        padding-left: 5px!important;
        height: 40px !important;
        line-height: 40px;
    }

    .ant-input-affix-wrapper.antd-country-phone-input {
        padding: 0;
        width: auto;
        border: none;
        top: 0 !important;

        /* desabilita o input do antd-country-phone-input
        para usar o antd-masked-input */
        input {
            // @extend .flatted-input;
            display: none;
        }

        .ant-input-prefix {
            margin-right: 10px;

            .ant-select-selector {
                min-width: 75px;
                height: 40px !important;
                padding: 0;

                .ant-select-selection-search {
                    input {
                        border: none !important;
                    }
                }

                .ant-select-selection-item {
                    padding: 0;
                    line-height: 40px;
                }
            }

            // remove arrow and search icon
            .ant-select-arrow {
                display: none;
            }
        }
    }
}

.ant-input-number.flatted-input-number {
    width: 100%;
    outline: none;
    box-shadow: none !important;
    border: none !important;

    .ant-input-number-handler-wrap {
        display: none;
    }

    .ant-input-number-input {
        height: 40px !important;
        @extend .flatted-input;

        &:focus,
        &:focus-visible {
            box-shadow: none !important;
            outline: none !important;
            border: none;
        }
    }
}

.ant-input.flatted-masked-input {
    height: 40px !important;
}

.ant-input {
    // fix bug: permite o InputNumber e MaskedInput expandir 100%
    font-size: initial !important;
}
