@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";

.signers-modal {
  .ant-modal-body .title {
    font-size: 24px;
  }

  .main-loading {
    height: 150px;
    .loading-container {
      position: relative;
      top: -10px;
    }
  }

  .blue-button,
  .white-button {
    height: 35px;
  }

  &.ant-modal {
    width: 45% !important;

    @media (max-width: 1080px) {
      width: 75% !important;
    }

    @media (max-width: 768px) {
      width: 95% !important;
    }
  }

  .warning-edit-signers {
    margin-top: 15px;

    span:first-child {
      @extend .normal-14;
      font-weight: 600;
      color: colors.$red-1;
    }
  }

  .signers-drop-menu {
    border: none !important;

    .ant-menu-item-selected {
      background-color: transparent !important;
    }

    .drop-arrow-options {
      padding-bottom: 20px;
      display: none;

      &.opened {
        display: block;
      }
    }

    .ant-menu-item {
      border: solid 1px colors.$gray-2;
      border-radius: 5px;

      .drop-arrow-menu-name {
        width: 100%;
        display: flex;
        cursor: pointer;
        font-weight: 500;
        justify-content: space-between;
        transition: all 0.2s ease-in-out;

        svg {
          color: colors.$gray-2 !important;
        }

        .count-signers {
          display: inline-block;
          background-color: #0029994d;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          line-height: normal;
          text-align: center;
          margin-left: 10px;
          font-size: 12px;

          > span {
            color: black;
            opacity: 0.7;
          }
        }
      }
    }

    .signer-form,
    .signer-row {
      width: 100%;
      margin: 12px 0;
      display: none;

      .ant-form {
        width: 100%;
      }

      &.opened {
        display: flex;
      }
    }

    .signer-form {
      .ant-select,
      .ant-select-selector,
      .ant-select-selection-search,
      .ant-select-selection-item {
        height: 32px !important;
      }

      .ant-select-selector {
        padding: 0 5px;
      }
    }

    .signer-row {
      background-color: colors.$gray-5;
      border-radius: 5px;
      padding: 5px 0px;
      line-height: 30px;
      position: relative;
      left: 8px;

      svg {
        color: colors.$gray-2 !important;
        cursor: pointer;
        height: 20px;
        position: relative;
        top: 5px;

        path {
          fill: colors.$gray-2 !important;
        }
      }

      .remove-signer-loading.loading-container {
        position: relative;
        top: -10px;
      }
    }
  }
}
