@use "../../common/styles/colors"as colors;

.config-page {

  .config-tab-content,
  .config-tab-bar {
    width: 1090px;
    padding: 0 12px;
  }

  .config-tab-content {
    min-height: 40vh;
  }

  .change-password-validation {
    display: flex;
    justify-content: center;
    margin-top: -10px;

    .checked {
      text-decoration: line-through;
    }

    .password-validation {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .empty-card {
    padding: 0 !important;
    display: flex;
    height: 269px;
    margin-top: 50px;
    text-align: center;
    border-radius: 8px;
    align-items: center;
    color: colors.$gray-2;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2),
      0 4px 8px 0 rgba(228, 228, 228, 0.2);

    img {
      width: 152px;
    }
  }
}