@use "../../../../styles/colors" as colors;
@use "../../../../styles/app.scss";

.signers-table {
  width: 100%;
  margin-bottom: 15px;

  .no-signers {
    @extend .normal-14;
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }

  .signers-title {
    @extend .normal-16-500;
    color: colors.$gray-2;
    padding-bottom: 15px;
  }

  .signers-hearder {
    color: colors.$gray-2;
    font-size: 12px;
  }

  .signers-body {
    @extend .normal-14-500;
  }
}
