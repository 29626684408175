@use "../../../../common/styles/colors";

.input-coupon {
    position: relative;
    margin-bottom: 26px;

    .error {
        color: colors.$red-0 !important;
        border-color: colors.$red-0 !important;
    }

    .success {
        color: colors.$green-1 !important;
        border-color: colors.$green-1 !important;
    }

    // 
    .simple-input {
        padding-right: 60px;
        margin-bottom: 16px;
        border-radius: 8px !important;
    }

    .text-feedback {
        bottom: -4px;
        position: absolute;
    }

    .btn-submit-coupon {
        right: 0;
        top: 22px;
        height: 40px;
        border: none;
        outline: none;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        padding-right: 12px;
        background-color: transparent;
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: .6;
        }

        .loading-container {
            width: 20px;

            .loading {
                left: 0;
                top: 10px;
                width: 18px;
                height: 18px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .input-coupon {
        .simple-input {
            height: 48px !important;
            &::placeholder {
                font-size: 14px;
            }
        }

        .btn-submit-coupon {
            top: 26px;
            font-size: 14px;
        }
    }
}