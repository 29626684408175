@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.download-contract-modal {
  .subtitle {
    @extend .normal-14-bold;
    margin-bottom: 10px;
  }

  .app-radio-button {
    margin-bottom: 10px;
  }

  .contract-icon-text {
    display: flex;
    align-items: center;
  }

  .contract-icon {
    svg {
      width: 25px;
      height: 25px;
      
      path {
        fill: colors.$gray-2;
      }
    }
  }
}
