@use '../../styles/colors' as colors;

.loading-container {
  .loading {
    background-size: auto;
    background-position: center;
    background-color: transparent;
    -webkit-animation: load 0.5s linear infinite;
    animation: load 0.5s linear infinite;
  }

  &.dark {
    .loading {
      background: url("../../../common/assets/loading.svg") no-repeat;
    }
  }

  &.light {
    .loading {
      background: url("../../../common/assets/loading_light.svg") no-repeat;
    }
  }

  &.center {
    height: 100%;
    width: 100%;

    .loading {
      top: 50%;
      left: 50%;
      position: relative;
    }
  }

  &.small {
    .loading {
      height: 20px;
      width: 20px;
    }
  }

  &.medium {
    .loading {
      height: 30px;
      width: 30px;
    }
  }

  &.big {
    .loading {
      height: 50px;
      width: 50px;
    }
  }
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
