@use "../../common/styles/colors";

.form-side.welcome {
  display: flex !important;
  max-width: unset !important;
  padding: unset !important;
}
.register-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #fff;

  .register-header {
    .header {
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      display: flex;
      z-index: 9999;
      padding: 0 14px;
      position: fixed;
      align-items: center;
      justify-content: space-between;
      background-color: colors.$white-1;
      box-shadow: 0 1px 9px rgba(109, 109, 109, 0.2);

      .logo {
        z-index: 1;
        width: 110px;
      }

      .blue-button {
        width: 125px;
        font-size: 12px;
      }
    }
  }

  .form-side {
    width: 100%;
    margin: 0 auto;
    max-width: 475px;
    text-align: left;
    box-sizing: border-box;
    padding: 0 14px 14px;
    min-height: calc(100vh - 195px);

    .form-title {
      font-size: 18px;
      cursor: pointer;
      font-weight: 700;
    }

    .form-subtitle {
      font-size: 12px;
      font-weight: 400;
    }

    .form-observation {
      font-size: 10px;
    }

    .ant-form {
      width: 100%;
      padding-top: 11px;
      margin: 16px auto 0;
      // border-top: 1px solid rgba(0, 0, 0, .15);

      .ant-form-item-explain {
        top: calc(100% - 17px);
        position: absolute;
      }

      .ant-form-item {
        margin: 0;

        .ant-form-item-label > label {
          height: 22px;
          font-size: 12px;
        }

        .ant-form-item-label {
          padding: 0;
          label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 6px;
            .circle-help-icon svg {
              width: 14px;
              margin-left: 6px;
              margin-bottom: -2px;
            }
          }
        }

        .simple-input,
        .antd-country-phone-input {
          width: 100%;
          font-size: 14px;
          margin: 0 0 16px;
          border-radius: 8px !important;

          .ant-input-prefix {
            margin-right: 10px;
          }

          &::placeholder {
            font-size: 14px;
            color: colors.$gray-2;
          }
        }

        &.ant-form-item-control-select {
          margin-bottom: 16px;

          .rounded-select {
            .ant-select-selector {
              padding: 0;
              width: 100%;
              height: 40px;
              text-indent: 10px;
              font-weight: normal;
              box-sizing: border-box;
              border-radius: 8px !important;
              border: 1px solid #525252 !important;

              .ant-select-selection-placeholder {
                opacity: 1;
                display: flex;
                font-size: 14px;
                align-items: center;
                color: colors.$gray-2 !important;
              }

              .ant-select-selection-search-input {
                font-size: 16px;
              }
            }
          }

          .ant-form-item-explain {
            top: 100% !important;
          }

          .ant-select-arrow {
            color: colors.$gray-2;
          }
        }

        &.ant-form-item-has-error {
          .simple-input,
          .rounded-select .ant-select-selector {
            border: 1px solid colors.$red-0 !important;
          }
        }
      }

      .container-select .ant-select {
        margin-top: 12px;

        .ant-select-selector {
          height: 50px;
        }
      }
    }

    .actions {
      // display: grid;
      grid-gap: 20px;
      margin-top: 32px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto 1fr;

      .coupon-terms {
        width: 100%;
        font-size: 12px;
        margin-bottom: 26px;
        grid-column: 1 / span 2;

        .ant-checkbox-wrapper {
          display: flex;
          font-size: 12px;
          align-items: center;

          .ant-checkbox {
            margin-top: -6px;
          }

          .ant-checkbox-inner::after {
            top: 50%;
            left: 50%;
            width: 8px;
            height: 12px;
            border: 3px solid #fff;
            border-top: 0;
            border-left: 0;
            transform: translate(-55%, -70%) rotate(45deg);
          }

          .ant-checkbox-checked::after {
            border: 1px solid transparent !important;
          }

          .ant-checkbox-inner {
            border-color: #ccc !important;

            &:hover {
              border-color: #ccc !important;
            }
          }

          &.ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: colors.$blue-0 !important;
          }

          .ant-checkbox-inner {
            &:hover {
              border-color: colors.$blue-0 !important;
            }
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            border-color: colors.$blue-0 !important;
            background-color: colors.$blue-0;
          }
        }
      }

      .outlined-button,
      .blue-button {
        height: 40px;
        min-width: 200px;
        padding: 0 2px;
        font-size: 11px;
      }

      .ant-row > .ant-col:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    @media (max-width: 768px) {
      .actions {
        .ant-row {
          justify-content: center;
          
          .ant-col {
            width: 100%;

            &:last-child {
                display: flex;
                justify-content: center;
            }
          }

          .outlined-button,
          .blue-button {
            width: 100%;
            min-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .register-container {
    .register-header {
      .header {
        height: 65px;
        padding: 0 42px;

        .logo {
          width: 150px;
        }

        .blue-button {
          width: 165px;
          font-size: 14px;
        }
      }
    }

    .form-side {
      max-width: 768px;
      padding: 0 42px 42px;
      margin: 20px auto 0;
      min-height: calc(100vh - 300px);

      .step-container {
        margin: 0 auto 46px;

        .step-title {
          margin: 30px 0;
          font-size: 18px;
          text-align: center;
        }
      }

      .form-title {
        font-size: 25px;
      }

      .form-subtitle {
        font-size: 14px;
      }

      .form-observation {
        font-size: 12px;
      }

      .ant-form {
        padding-top: 24px;
        margin: 29px auto 0;

        .junction-inputs {
          display: grid;
          grid-gap: 16px;
          grid-template-columns: 1fr 1fr;
        }

        .ant-form-item {
          margin: 0;

          .ant-form-item-explain {
            top: calc(100% - 26px);
            position: absolute;
          }

          .ant-form-item-label > label {
            font-size: 14px;
            padding-bottom: 4px;
          }

          .ant-form-item-label {
            .circle-help-icon svg {
              width: 15px;
            }
          }

          .simple-input,
          .antd-country-phone-input {
            font-size: 15px;
            margin: 0 0 24px;

            .ant-input-prefix {
              margin-right: 10px;
            }

            &::placeholder {
              font-size: 14px;
            }
          }
        }

        .container-select .ant-select {
          margin-top: 12px;

          .ant-select-selector {
            height: 50px;
          }
        }
      }

      .actions {
        margin-top: 56px;

        .outlined-button,
        .blue-button {
          height: 50px;
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .register-container {
    .register-header {
      width: 100%;

      .header {
        height: 85px;
        padding: 0 81px;
        box-shadow: none;
        position: relative;
        background-color: transparent;

        .logo {
          width: 195px;
        }

        .blue-button {
          height: 42px;
          width: 190px;
          // font-size: 16px;
        }
      }
    }

    .form-side {
      display: grid;
      max-width: none;
      padding: 60px 81px;
      grid-gap: 35px 43px;
      grid-template-columns: 1fr 1fr;
      min-height: calc(100vh - 280px);
      grid-template-rows: auto auto 1fr;

      .form-title {
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      .step-about {
        .form-subtitle {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .form-observation {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .ant-form {
        margin: 0;
        border: none;
        padding-top: 0;
        grid-column: 1;
        grid-row: 2 / 4;

        .junction-inputs {
          display: grid;
          grid-gap: 16px;
          grid-template-columns: 1fr 1fr;
        }

        .junction-inputs-nnc {
          display: grid;
          grid-gap: 16px;
          grid-template-columns: 2fr 1fr 1fr;
        }

        .ant-form-item {
          margin: 0;

          .ant-form-item-explain {
            top: calc(100% - 26px);
            position: absolute;
          }

          .ant-form-item-label > label {
            font-size: 14px;
            padding-bottom: 4px;
          }

          .ant-form-item-label {
            label {
              display: flex;
              white-space: nowrap;
              justify-content: space-between;
              .circle-help-icon svg {
                width: 16px;
                margin-bottom: -2px;
              }
            }
          }

          .simple-input,
          .antd-country-phone-input {
            height: 48px !important;
            font-size: 15px;
            margin: 0 0 24px;

            .ant-input-prefix {
              margin-right: 10px;
            }

            &::placeholder {
              font-size: 14px;
            }
          }
        }

        .ant-select {
          .ant-select-selector {
            height: 48px !important;

            .ant-select-selection-item {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .plan-description {
        grid-column: 2;
        grid-row: 1 / 3;
      }

      .actions {
        margin-top: 0;
        grid-column: 2;
        grid-gap: 0 40px;

        .coupon-terms {
          width: 100%;

          .ant-checkbox-wrapper {
            display: flex;
            font-size: 12px;
            align-items: center;
            margin: 0 auto 20px 0;

            .ant-checkbox-inner::after {
              top: 50%;
              left: 50%;
              width: 8px;
              height: 12px;
              border: 3px solid #fff;
              border-top: 0;
              border-left: 0;
              transform: translate(-55%, -70%) rotate(45deg);
            }

            .ant-checkbox-checked::after {
              border: 1px solid transparent !important;
            }

            .ant-checkbox-inner {
              border-color: #ccc !important;

              &:hover {
                border-color: #ccc !important;
              }
            }

            &.ant-checkbox-input:focus + .ant-checkbox-inner {
              border-color: colors.$blue-0 !important;
            }

            .ant-checkbox-inner {
              &:hover {
                border-color: colors.$blue-0 !important;
              }
            }

            .ant-checkbox-checked .ant-checkbox-inner {
              border-color: colors.$blue-0 !important;
              background-color: colors.$blue-0;
            }
          }
        }

        .outlined-button,
        .blue-button {
          width: 100%;
          height: 48px;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .register-container {
    .register-header {
      .header {
        height: 85px;
        margin: 0 auto;
        max-width: 1440px;
      }
    }

    .form-side {
      max-width: 1440px;
    }
  }
}
