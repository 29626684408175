@use "../../common/styles/colors" as colors;
@import "../../common/styles/app.scss";

.create-proposal {
  overflow: hidden;

  .proposal-success-modal {
    .ant-modal-content .ant-modal-body .title {
      color: green !important;
    }
  }

  .ant-form {
    .step {
      padding: 24px;
      border-radius: 8px;
      margin-bottom: 24px;
      background-color: colors.$white-2;
      border-radius: 8px; 
      border: 1px #D9D9D9 solid;

      &.step-no-style {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        border: none;
      }
    }

    .row-input-tittle{
      max-width: 528px;
    }

    .input-label-help {
      @extend .ant-form-item-label;
      color: rgba(0, 0, 0, 0.85);
      width: 100%;

      .circle-help-icon {
        position: relative;
        top: 5px;
        left: 3px;
      }
    }

    .input-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 7px;
    }

    .title-textarea {
      @extend .normal-14-500;
      padding-bottom: 6px;
    }

    .input-subtitle {
      padding-bottom: 20px;
    }

    .input-description {
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .styled-switch {
      .ant-switch {
          height: 18px !important;
          width: 35px !important;
      }

      .ant-switch-checked {
          background-color: var(--primary-blue) !important;
          height: 18px !important;
          width: 35px !important;
      }

      .ant-switch-handle {
          height: 18px !important;
          width: 18px !important;
      }

      .ant-switch-handle::before {
          height: 18px !important;
          width: 18px !important;
      }

      .ant-switch-checked .ant-switch-handle::before {
          margin-left: 2px;
      }

      .switch-label {
        @extend .ant-form-item-label ;
      }
    }


    

    .ant-row.campaign-format-style {
      padding-bottom: 67px;

      &[data-is-last="true"] {
        padding-bottom: 0px;
      }
    }

    .ant-row {
      .ant-col {
        padding: 0;

        .flatted-input {
          width: 100%;
          padding-top: 7px;
        }
      }

      .ant-form-item {
        .ant-select-selection-placeholder,
        .flatted-input::placeholder {
          color: colors.$gray-1;
        }

        .flatted-phone-input {
          justify-content: flex-start;
          .ant-input-affix-wrapper.antd-country-phone-input {
            height: 40px !important;

            .ant-input-prefix {
              border: none !important;
              padding: 6px 0 !important;
              margin-right: 8px !important;
            }
          }
        }

        // ant-input-affix-wrapper antd-country-phone-input
      }

      .ant-form-item-control-input,
      .ant-form-item-control-input-content {
        height: auto;
      }

      .circle-check-box {
        margin-bottom: 15px;
      }

      .checkbox-square {
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: var(--primary-blue) !important;
          border: 1px solid var(--primary-blue) !important;
        }

        .ant-dropdown-menu-item .ant-dropdown-menu-item-active .ant-dropdown-menu-item-only-child {
          background-color: var(--primary-blue) !important;
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner {
        border: 1px solid colors.$gray-1;
      }
    }

    .btn-back {
      height: 48px;
      border: none;
      display: flex;
      cursor: pointer;
      margin-top: -48px;
      align-items: center;
      color: colors.$black-0;
      background-color: transparent;
      transition: all 0.2s ease-in-out;

      .arrow-right-icon {
        width: 24px;
        display: flex;
        margin-top: -4px;
        margin-right: 8px;

        svg path {
          fill: colors.$black-0 !important;
        }
      }

      .button-text {
        font-size: 16px;
      }

      &:hover {
        color: var(--primary-blue);

        .arrow-right-icon svg path {
          fill: var(--primary-blue) !important;
        }
      }
    }

    .ant-divider-horizontal {
      margin: 0 0 32px 0;

      &.divider-margin-top {
        margin-top: 24px ;
      }
    }

    // .outlined-blue-button,
    // .white-button,
    // .blue-button {
    //   height: 48px;
    //   width: 240px;
    //   border-radius: 28px;
    // }

    .previous-button,
    .submit-button {
      display: flex;
      justify-content: flex-start;

      &.submit-button {
        justify-content: flex-end;
      }

      button {
        // margin-top: 33px;
        min-width: 208px;
      }

      @media screen and (max-width: 768px) {
        justify-content: center !important;

        button {
          min-width: 100%;
          margin-bottom: 15px;
        }
      }
    }

    .outlined-blue-button {
      margin: 0 auto 0 0;
      color: var(--primary-blue);
      border-color: var(--primary-blue);
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
  }
  #days_to_payment_type {
    height: 40px;
    align-items: flex-end;
    display: inline-flex;
  }
}
