@use "../../common/styles/colors" as colors;

.contract-history-page {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1090px;
    padding: 0 12px 20px;
    flex-direction: column;

    .filter-box {
        height: 61px;
        display: flex;
        line-height: 23px;
        align-items: center;
        justify-content: flex-start;
        margin: 24px 0;
        padding: 8px 24px !important;
        background-color: colors.$white-2;

        .container-select {
            margin-right: 16px;
            padding-right: 16px;
            border-right: 1px solid colors.$gray-9;
        }

        .filter-date-box {
            height: 100%;
            display: flex;
            margin: 0 16px;
            padding: 0 16px;
            align-items: center;
            border-left: 1px solid colors.$gray-9;
            border-right: 1px solid colors.$gray-9;

            .normal-16-500 {
                padding-right: 4px;
            }

            .text-date {
                font-size: 16px;
                padding: 2px 4px;
                cursor: pointer;
                border-radius: 4px;
                color: colors.$gray-2;
                transition: all 0.2s ease-in-out;

                &.text-a {
                    margin-right: -3px;

                    &:hover {
                        background-color: transparent;
                    }
                }

                &:hover {
                    background-color: colors.$gray-9;
                }
            }

            .ant-dropdown-trigger {
                margin: 0;
                width: 1px;
                opacity: 0;
                padding: 0;
            }

            .calendar-icon {
                cursor: pointer;
                margin-left: 14px;
                height: 25px !important;
                transition: all 0.2s ease-in-out;

                &:hover {
                    opacity: 0.6;
                }

                svg path {
                    fill: colors.$gray-2;
                }
            }
        }
    }

    .loading-container {
        margin-top: 50px;
    }

    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-color: transparent !important;
    }

    .ant-table {
        background-color: transparent !important;

        thead {
            th {
                border-bottom: 1px solid #c2c2c2;
                background-color: transparent !important;

                &:before {
                    display: none !important;
                }
            }
        }

        tbody {
            tr,
            td {
                background-color: transparent !important;
            }
            tr > td {
                height: 55px;
                padding: 0 16px !important;
            }
        }

        .actions {
            display: flex;
            align-items: center;

            .show-button {
                margin-left: -16px;
            }

            .signature-button {
                margin: 0 10px;
            }

            svg {
                font-size: 20px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    opacity: 0.6;
                    cursor: pointer;
                }
            }
        }

        .link-14 {
            color: #141414;
        }
    }

    .ant-spin-dot-item {
        background-color: var(--primary-blue) !important;
    }

    .ant-pagination {
        display: flex;
        margin-top: 32px;
        justify-content: center;

        .ant-pagination-next,
        .ant-pagination-prev {
            &.ant-pagination-disabled:hover {
                button {
                    color: rgba(0, 0, 0, 0.25) !important;
                }
            }

            button {
                border: none;
                border-radius: 50%;
                background-color: transparent;
                &:hover {
                    color: var(--primary-blue) !important;
                }
            }
        }

        .ant-pagination-item {
            border: none;
            border-radius: 50%;
            background-color: transparent;

            &:hover {
                a {
                    color: var(--primary-blue) !important;
                }
            }

            &.ant-pagination-item-active {
                background-color: var(--primary-blue);

                a {
                    color: #fff !important;
                }
            }
        }
    }

    .empty-card {
        padding: 0 !important;
        display: flex;
        height: 269px;
        margin-top: 24px;
        text-align: center;
        border-radius: 8px;
        align-items: center;
        color: colors.$gray-2;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2),
            0 4px 8px 0 rgba(228, 228, 228, 0.2);

        img {
            width: 152px;
        }
    }
}

.date-picker-calendar {
    width: 240px;
}
