@use "../../../../common/styles/colors"as colors;

.topbar-container-box {
    $border-color: colors.$gray-7;
    $background-suitcase-icon: colors.$blue-7;
    $role-color: var(--primary-blue);
    $interprise-color: colors.$blue-4;

    height: 50px;
    width: 180px;
    display: flex;
    justify-content: flex-start;
    border: 1px solid $border-color;
    border-radius: 8px;
    background-color: transparent;
    padding: 9px 16px 9px 16px;
    margin-left: 27px;
    cursor: pointer;
    &:hover .role-icon-first span {
        border: 1px solid rgba(140, 165, 234, 1);
        margin-top: -1px;
    }

    &:hover {
        background-color: rgba(217, 225, 248, 1);
        border-color: rgba(140, 165, 234, 1);
    }

    &:active {
        background-color: rgba(191, 205, 243, 1);
        border-color: rgba(140, 165, 234, 1);
    }




    .style-role {
        color: $role-color !important;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 600;
    }

    .icon-suitcase-box {
        .role-icon-first, .role-icon-second {
            span {
                width: 32px;
                height: 32px;
                background-color: $role-color;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                padding-top: 2px;
               
            }

            .suitcase-icon {
                background-color: $background-suitcase-icon;
            }

            svg {
                width: 25px;
                height: 25px;
            }
        }

    }


}