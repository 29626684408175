$mobile-screen: 768px;

.ant-modal.change-payment-method-modal {
  @media (max-width: $mobile-screen) {
    top: 0 !important;
    padding: 0 !important;
    .ant-modal-content {
      height: 100vh !important;
    }

    .ant-modal-body {
      min-height: 76vh !important;
    }
  }
 
    .ant-form-item {
      height: 100%;
      .ant-form-item-control {
        justify-content: flex-end;
      }
    }
   
}

