@use "./colors";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");

html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
}

body {
    --primary-blue: #0018e8; // não é possível passar a cor usando as variáveis no _colors.scss
}

.font-14 {
    color: colors.$black-0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}

.normal-14 {
    color: colors.$black-0;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: normal;
}

.normal-14-500 {
    @extend .normal-14;
    font-weight: 500;
}

.normal-14-bold {
    @extend .normal-14;
    font-weight: bold;
}

.link-14 {
    color: colors.$blue-1;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    cursor: pointer;
}

.normal-12 {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: normal;
}

.normal-12-500 {
    @extend .normal-12;
    font-weight: 500;
}

.normal-12-700 {
    @extend .normal-12;
    font-weight: 700;
}

.normal-18 {
    color: colors.$black-0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
}

.normal-18-bold {
    @extend .normal-18;
    font-weight: 600;
}

.normal-18-700 {
    @extend .normal-18;
    font-weight: 700;
}


.centralize-text {
    text-align: center;
}

.justify-text {
    text-align: justify;
}

.App {
    height: 100%;
}

.normal-36 {
    color: colors.$black-0;
    font-family: Poppins;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 55px;
    font-weight: normal;
}

.normal-36-700 {
    color: colors.$blue-1;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: "Montserrat", sans-serif;
}

.normal-28 {
    color: colors.$gray-10;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.normal-30 {
    color: colors.$black-0;
    font-family: Poppins;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 55px;
    font-weight: normal;
}

.normal-14-blank {
    @extend .font-14;
    color: colors.$white-2;
}

.normal-36-blank {
    @extend .normal-36;
    color: colors.$white-2;
}

.normal-30-blank {
    @extend .normal-30;
    color: colors.$white-2;
}

.normal-24 {
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    font-weight: normal;
}

.normal-20 {
    font-family: Poppins;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: normal;
}

.normal-20-500 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
}

.normal-24-bold-green {
    @extend .normal-24;
    font-weight: 600;
    color: colors.$green-0;
}

.normal-14-500-green {
    @extend .normal-14;
    font-weight: 500;
    color: colors.$green-1;
}

.normal-16 {
    color: colors.$black-0;
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: normal;
}

.normal-16-600 {
    color: colors.$black-0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: normal
}

.normal-16-500 {
    @extend .normal-16;
    font-weight: 500;
}

.normal-16-700 {
    @extend .normal-16;
    font-weight: 700;
}

.normal-24-500 {
    @extend .normal-24;
    font-weight: 500;
}

.normal-14-300 {
    @extend .normal-14;
    font-weight: 300;
}

.normal-24-500-gray {
    @extend .normal-24-500;
    color: colors.$gray-0;
}

.normal-36-500 {
    @extend .normal-36;
    font-weight: 500;
}

.ant-form-item {
    padding: 5px 0 !important;
}

.ant-form-item-explain {
    @extend .normal-12;
    font-size: 12px !important;
}

.ant-form-item-required,
.ant-form-item-label {
    @extend .normal-14-500;
}

.add-space-bottom-label .ant-form-item-label{
    margin-bottom: 5px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none !important;
}

.ant-select-selector {
    height: 40px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid colors.$gray-1 !important;
    box-shadow: none !important;
    width: 100%;

    input {
        @extend .normal-16;
        color: colors.$gray-0 !important;
    }

    input::placeholder,
    .ant-select-selection-placeholder {
        @extend .normal-16;
        color: colors.$gray-1 !important;
        opacity: 0.7;
    }
}

.ant-select-selection-item {
    @extend .normal-16;
    color: colors.$gray-0 !important;
}

.ant-select-item .ant-select-item-option .ant-select-item-option-active .ant-select-item-option-selected {
    @extend .normal-16;
    color: colors.$gray-0 !important;
}

.ant-select-dropdown {
    box-shadow: 0 5px 15px 0 rgba(27, 27, 78, 0.1) !important;
    border-radius: 10px;
}

.ant-select-item {
    @extend .normal-16;
    font-size: 16px !important;
    color: colors.$gray-0 !important;

    span {
        margin-bottom: 18px;
        margin-left: 15px;
    }
}

.ant-select-item-option-selected {
    background-color: white !important;
}

.ant-select-selection-placeholder {
    @extend .normal-16;
}

// custom scrollbar
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: colors.$gray-7;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: colors.$gray-2;
}

.not-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
}

.grecaptcha-badge {
    visibility: hidden;
}