@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.bread-message {
    @extend .normal-12;
    color: colors.$black-0;
    // background-color: colors.$red-5;
    border-radius: 50px;
    padding: 4px 10px ;
    line-height: 15px;

    > span {
        svg {
            width: 15px;
            height: 15px;
            margin-right: 8px;
            // color: colors.$red-4;
            position: relative;
            top: 1px;

            // path {
            //       fill: colors.$red-4;
            // }
        }

        &:last-child {
            position: relative;
            top: -2px;
        }

        &[data-visible="true"] {
            display: block;
        }

        &[data-visible="false"] {
            display: none;
        }
    }
}

.bread-message-error {
    background-color: colors.$red-5;

    span svg {
        color: colors.$red-4;

        path {
            fill: colors.$red-4;
        }
    }
}

.bread-message-alert {
    background-color: colors.$yellow-1;

    span svg {
        width: 16px;
        height: 16px;
        position: relative;
        top: 2px;

        color: colors.$yellow-2;
        path {
            stroke: colors.$yellow-2;
            fill: transparent;
        }
    }
}

.bread-message-success {
    background-color: colors.$green-4;

    span svg {
        color: colors.$green-2;

        path {
            fill: colors.$green-2;
        }
    }
}
