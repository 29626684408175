@use "../../common/styles/colors" as colors;

body {
    background-color: colors.$gray-3 !important;

    &.body-contractor {
        --primary-blue: #002999;
        --antd-wave-shadow-color: #00227e;
        --primary-blue-hover: rgba(0, 056, 209, 0.05);
    }

    &.body-contracted {
        --primary-blue: #0018e8; // não é possível passar a cor usando as variáveis no _colors.scss
        --antd-wave-shadow-color: #0018e8;
        --primary-blue-hover: rgba(0, 056, 209, 0.05);
    }

    .body-container {
        .left-bar {
            width: 64px;
            height: 100%;
            z-index: 1000;
            position: fixed;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            background-color: colors.$white-1;
            box-shadow: 0 1px 5px 0 rgba(209, 209, 209, 0.2), 0 3px 1px -2px rgba(207, 207, 207, 0.12),
                0 2px 2px 0 rgba(79, 79, 79, 0.14);

                .expanded-arrow {
                    border-radius: 100%;
                    text-align: center;
                    background-color: colors.$blue-4;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
                    cursor: pointer;
                    position: absolute;
                    right: -15px;
                    top: 41px;
                    z-index: 10;

                    svg {
                        path {
                            height: 12px;
                            width: 6px;
                            fill: white;
                        }
                    }
                }

            .logo {
                margin-top: 35px;
                text-align: center;
                display: flex;
                position: relative;
                right: -15px;
                align-items: flex-start;

                img {
                    width: 37px;
                    height: 37px;
                }

                
            }
            &.left-bar-true{
                width: 260px; // Largura expandida
                transition: width 0.5s ease; 
                z-index: 10000;
                .icon-title {
                    display: flex;
                    opacity: 1;
                    transition-delay: 0.3s;
                }

                .side-menu li.menu-item-plus.menu-item {
                    align-self: flex-start;
                }

                .side-menu .menu-item {
                    width: 100%;
                }

                .side-menu {
                    justify-content: flex-start;
                }
            }
            &.left-bar-false {
                transition: 0.5s ease;

                .side-menu {
                    justify-content: flex-start;
                }
            }
            
            
        }


        .top-bar {
            width: 100%;
            height: 90px;
            padding-left: 64px;
            background-color: white;
        }

        .content {
            padding: 40px 0 40px 64px;
        }

        &.body-container-contracted {
            .selected {
                //
            }
        }

        &.body-container-contractor {
            .blue-button {
                background-color: var(--primary-blue);
            }

            .left-bar {
                background-color: var(--primary-blue);
            }
        }
    }
}