@use '../../../../common/styles/colors'as colors;
@use '../../../../common/styles/app.scss';

.default-container {
    width: 100%;
    height: 145px;
    padding: 12px 27px 12px 27px !important;
    background-color: colors.$white-2;
    border: 1px solid var(--color-neutral-light, #E0E0E0);

    .col-title {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        // padding-bottom: 16px;

        .icon-title {
            margin-right: 8px;
            top: 2px;
            position: relative;

            svg {
                path {
                    stroke: #2FB14A;
                }
            }
        }

        .title {
            // color: colors.$blue-4;
            color: #2FB14A;
        }
    }

    .ant-row {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;

        .news {
            margin-right: 21px;
        }

        .link {
            white-space: nowrap;
            text-decoration-line: underline;
            color: colors.$blue-4;
        }
    }

    .style-dots {
        .ant-col {

            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            justify-content: center;

            .dots {
                width: 8px;
                height: 8px;
                background: colors.$gray-11;
                border-radius: 100%;
                margin-right: 5px;
            }
            .dots:first-child {
                background:colors.$blue-1; 
            }
        }

    }
}