@use "../../../../common/styles/_colors";


.segment {
    display: flex;
    position: relative;
    margin: 0 auto 20px 0;
    border-bottom: 1px solid colors.$gray-4;

    .segment-option {
        width: 50%;
        height: 40px;
        display: flex;
        padding: 8px 0;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
        align-items: center;
        justify-content: center;
        transition: .3s all ease-in-out;

        &.active {
            color: colors.$blue-0;
        }
    }

    .segment-underline {
        left: 0;
        width: 50%;
        height: 2px;
        bottom: -2px;
        position: absolute;
        border-radius: 0 0 2px 2px;
        transition: .3s all ease-in-out;
        background-color: colors.$blue-0;
    }
}

.types {
    font-size: 18px;
    margin-top: 12px;

    .text-plan-type {
        font-weight: 600;
    }
}

.junction-validity {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.junction-cvv {
    display: flex;
    align-items: center;

    .ant-form-item {
        width: 70%;
    }

    .cvv-img {
        width: 30%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 36px;
        }
    }
}

.iugu-politic-privacy {
    font-size: 14px;
    margin: 10px 0 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid colors.$gray-0;

    .link {
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: 0.6;
        }
    }
}

.purchase-summary {
    .title {
        font-size: 18px;
        font-weight: 700;
    }

    .text {
        display: flex;
        margin: 0 0 4px;
        font-size: 14px;
        justify-content: space-between;
    }
}

.airfluencers-terms {
    margin-top: 20px;
    font-size: 14px;

    .link {
        cursor: pointer;
        color: colors.$blue-0;
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: 0.6;
        }
    }
}

@media screen and (min-width: 768px) {
    .segment {
        margin: 20px auto 40px 0;

        .segment-option {
            height: 45px;
            font-size: 15px;
            padding-bottom: 12px;
        }

        .segment-underline {
            height: 3px;
        }
    }

    .junction-validity {
        flex-direction: row;

        .ant-form-item {
            width: 48%;
        }
    }

    .junction-cvv {
        .ant-form-item {
            width: 80%;
        }

        .cvv-img {
            width: 20%;

            img {
                height: 40px;
            }
        }
    }


    .iugu-politic-privacy {
        font-size: 18px;
        margin: 25px 0 30px;
        padding-bottom: 25px;
    }

    .purchase-summary {
        .title {
            font-size: 21px;
        }

        .text {
            margin: 0 0 4px;
            font-size: 18px;
        }
    }

    .airfluencers-terms {
        font-size: 18px;
        margin-top: 30px;
    }
}

@media screen and (min-width: 1024px) {
    .segment {
        margin: 20px auto 32px 0;

        .segment-option {
            height: 45px;
            font-size: 20px;
            padding-bottom: 12px;
        }

        .segment-underline {
            height: 3px;
        }
    }

    .junction-cvv {
        .ant-form-item {
            width: 70%;
        }

        .cvv-img {
            width: 30%;

            img {
                height: 36px;
            }
        }
    }

    .iugu-politic-privacy {
        font-size: 16px;
        margin: 20px 0 24px;
        padding-bottom: 24px;
    }

    .purchase-summary {
        .title {
            font-size: 24px;
            margin-bottom: 12px;
        }

        .text {
            margin: 0 0 4px;
            font-size: 16px;
        }
    }

    .airfluencers-terms {
        font-size: 16px;
        margin-top: 24px;
    }
}