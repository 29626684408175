@use "../../../../common/styles/colors"as colors;
@use "../../../../common/styles/app.scss";


.card-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  border-radius: 8px;
  background-color: colors.$white-2;
  margin-top: 12px;
  padding: 16px;
  box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2),
    0 4px 8px 0 rgba(228, 228, 228, 0.2);
  cursor: pointer;

  .join {
    display: flex;
    align-items: center;
  }

  .ant-col {
    display: grid;
    align-items: center;
    padding-right: 10px;
  }
  
}
