@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.edit-field-modal {
  .input-title,
  .input-description {
    text-align: center;
    display: block;
    width: 100%;
  }

  .input-title {
    margin: 0 !important;

    input {
      // padding: 0 20px !important;
      width: 100%;
      max-width: 100%;
    }

    .ant-form-item-explain {
      top: 100%;
      position: absolute;
      padding-left: 20px;
    }
  }
}

.meeting-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .form-metting{
    padding-bottom: 50px
  }

 

  .blue-button {
    @extend .normal-14;
    color: white;
    // width: 70px !important;
    height: 30px !important;
    padding: 0 15px !important;
    
  }
  .submit-blue-button{
    width: 100%;
    margin-top: 52px;
  }

  input#summary {
    width: 100%;
    max-width: 100% !important;
  }

  .ant-form .ant-row:first-child {
    padding-top: 10px;
  }

  .header-icons {
    svg {
      width: 11px;
      height: auto;
    }
  }

  .divider-title {
    margin-top: 0 !important;
  }

  .selected-date-time-box {
    @extend .normal-12-500;
    

    span {
      cursor: pointer;

      &[data-active="true"] {
        color: var(--primary-blue);
      }

      &[data-selected="false"] {
        color: colors.$gray-1;
      }
    }
  }

  .meeting-calendar {
    min-height: 250px;
    .pick-calendar-meeting{
      position: relative;
    }
  }

  .meeting-timer {
    min-height: 80px;
    line-height: 80px;
  }

  .meeting-menu {
    cursor: pointer;
    align-items: baseline;
  }

  .add-field-button {
    margin-bottom: 20px;
    
    &:last-child {
      margin-bottom: 0px;
    }

    .style-timer .ant-select-arrow {
      display: none;
    }
    
    .style-timer .ant-select-selector {
      border-bottom: none !important;
    }
    

    svg {
      width: 18px;
      height: auto;
      position: relative;
      top: 2px;

      path {
        fill: colors.$gray-2;
      }
    }

    >span:last-child {
      margin-left: 10px;
    }

    .text-date-picker {
      >span:first-child {
        
        min-width: 180px;
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 0px;
        text-align: center;
        
      }
    }
  }

  .metting-people-box {
    @extend .normal-12;
    width: 100%;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 3px 0;

      span:first-child {
        color: colors.$blue-1;
        padding-left: 20px;
      }
    }
  }

  .date-picker-container {
    .date-picker-calendar {
      border: 0;
      padding: 0;

      .react-calendar {
        max-width: 400px;
      }

      .react-calendar__month-view {
        .react-calendar__month-view__days {
          .react-calendar__tile {
            height: 38px !important;
            font-size: 13px !important;
            border-width: 5px;
          }
        }
      }
    }
  }
}

