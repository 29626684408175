@use "../../../common/styles/colors"as colors;
@use "../../../common/styles/app.scss";

.side-menu {
    $icon-color: colors.$gray-2;
    $icon-color-active: var(--primary-blue);
    $icon-color-hover: var(--primary-blue-hover);
    $icon-color-contrast: colors.$white-2;

    margin-top: 62px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;

    ul {
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;

    }



    li.menu-item-plus.menu-item {
        padding-left: 8px;
        align-self: center;
    }

    .menu-item {
        width: 100%;
        height: 50px;
        display: flex;
        cursor: pointer;
        align-items: center;
        flex-direction: row;
        transition: all 0.4s ease-in-out;
        padding-left: 16px;
        gap: 15px;
        align-self: center;
        margin: 0 auto;



        .icon-title {
            color: $icon-color;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;

        }

        svg {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            color: $icon-color;
            justify-content: center;

            path {
                fill: $icon-color;
            }
        }

        .home-icon,
        .credit-card-icon {
            width: 32px;
            height: 32px;

            svg {
                width: 37px;
                height: 37px;
            }
        }

        .home-icon {
            position: relative;
            left: -3px;       
            top: -4px 
        }

            
        .benefits-icon {
            width: 30px;
            height: 30px;

            svg {
                width: 40px;
                height: 40px;
                position: relative;
                left: -5px;       
                top: -5px 
            }
        }

        .reports-icon {
            svg {
                path:first-child {
                    fill: transparent !important;
                    stroke: $icon-color !important;
                }
            }
        }

        &.selected {
            background-color: $icon-color-hover;
            position: relative;

            &::after {
                content: "";
                width: 2px;
                height: 100%;
                background-color:  $icon-color-active;
                display: block;
                position: absolute;
                right: 0;
                transform: translateY(0px);
                transition: all 0.4s ease-in-out;
            }

            .icon-title {
                color: $icon-color-active;
            }


            svg {
                color: $icon-color-active;

                path {
                    fill: $icon-color-active;
                }
            }

            .reports-icon {
                svg {
                    path:first-child {
                        stroke: $icon-color-active !important;
                    }
                }
            }
        }

        // &:last-child {
        //     position: fixed;
        //     bottom: 10px;

        //     svg {
        //         width: auto;
        //         height: 16px;
        //         position: relative;
        //         top: 4px;

        //         path {
        //             fill: $icon-color;
        //         }
        //     }
        // }

        &:hover {
            background-color: $icon-color-hover;
        }

        &.menu-item-plus {
            background-color: transparent !important;


            .anticon-plus {
                min-width: 50px;
                min-height: 50px;
                padding: 7px 0;
                border-radius: 3px;
                background-color: $icon-color-active;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.39);
                display: flex;
                align-items: center;


                svg {
                    height: 30px;
                    margin: 0 auto;

                    path {
                        fill: $icon-color-contrast;
                    }
                }
            }
        }
    }

    .selected {
        svg {
            color: $icon-color-active;
        }
    }

    // .active-marker {
    //     width: 3px;
    //     height: 40px;
    //     position: absolute;
    //     margin-left: 61px;
    //     transform: translateY(0px);
    //     transition: all 0.4s ease-in-out;
    //     background-color: 
    //     right: 0;

    //     &.position-3 {
    //         opacity: 0;
    //     }
    // }
}

.body-container-contractor {
    $icon-color: colors.$white-2;
    $icon-color-active: colors.$white-2;
    $icon-color-hover: #00227e;
    $icon-color-contrast: var(--primary-blue);

    .side-menu {
        .menu-item {
            svg {
                color: $icon-color;

                path {
                    fill: $icon-color;
                }
            }
            .icon-title {
                color: $icon-color;
            }

            &.menu-item-plus {

                &.selected {
                    background-color: $icon-color-hover;
                }

                .anticon-plus {
                    background-color: $icon-color;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;

                    svg path {
                        fill: $icon-color-contrast !important;
                    }
                }
            }

            .reports-icon {
                svg {
                    path:first-child {
                        fill: transparent !important;
                        stroke: $icon-color !important;
                    }
                }
            }

            &.selected {
                background-color: $icon-color-hover;

                svg {
                    color: $icon-color-active;

                    path {
                        fill: $icon-color-active;
                    }
                }

                .reports-icon {
                    svg {
                        path:first-child {
                            stroke: $icon-color-active !important;
                        }
                    }
                }

                &::after {
                    background-color:  $icon-color-active;
                }
            }
        }

        .active-marker {
            background-color: $icon-color-active;
        }
    }
}