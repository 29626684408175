@use "../../../common/styles/colors" as colors;

.proposal-status {
  .proposal-icon {
    svg {
      position: relative;
      // top: 5px;
      width: 20px;
      height: auto;
    }

    &.proposal-canceled-icon svg {
      path {
        stroke: colors.$blue-1;
      }
    }

    &.proposal-waiting_sign_contract-icon svg,
    &.proposal-expired-icon svg{
      width: 29px;
      top: 9px;
    }
  }
}
