@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.time-picker-proposal {
  border: none !important;
  box-shadow: none !important;
 
  .ant-picker-dropdown {
    position: absolute !important; 
    z-index: 9999;
  }

  .ant-picker-input > input {
    @extend .normal-24;
    text-align: center;
  }
}

.ant-picker-ok {
  span {
    color: white;
    background: var(--primary-blue);
  }

  button,
  button:hover {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue);
  }
}

.ant-picker-now a {
  color: var(--primary-blue) !important;
}
