@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.row-title {

    .title-icon {
        // #002999

        svg {
            width: 23px !important;
            height: 23px !important;
            position: relative;
            left: -5px;
            top: 4px;
    
            path {
                fill: colors.$blue-0 !important;
            }
        }
        
        &.onboardingDocument-icon,
        &.onboardingDocNfse-icon {
            position: relative;
            left: -3px;
            top: -2px;
        }


        &.clips-icon {
            position: relative;
            top: -2px;
        }

        &.suitcase-icon {
            background-color: transparent;
            svg {

                path {
                    fill: transparent !important;
                    stroke: colors.$blue-0 !important;
                }
            }
         }
    }


    .edit-btn {
        @extend .normal-12;
        color: colors.$blue-0;
        cursor: pointer;
        margin-left: 30px;

        svg {
            width: 14px;
            height: 14px;
            position: relative;
            top: 2px;
            color: colors.$blue-0;

        }
    }

    .collapse-toggle-icon {
        cursor: pointer;
        transition: 0.8s opacity;
        
        svg {
            width: 18px;
            height: 18px;

            path {
                fill: colors.$gray-7 !important;
            }
        }
    }
}