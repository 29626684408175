@use "../../../../common/styles/colors"as colors;


@media screen and (min-width: 768px) {

    .plan-name-style {
        font-weight: 700;
    }

    // .register-container .form-side {
    //     display: flex !important;
    //     max-width: unset !important;
    //     padding: unset !important;

    // }


    .welcome-screen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        max-width: 894px;
        margin: 0 auto;
        gap: 40px;
        position: relative;



        .background-image {
            background-image: url("../../../../common/assets/finishedRegister/finished-Image-1.svg");
            background-size: cover;
            background-position: center;
            height: 303px;
            width: 894px;
            position: relative;
            background-repeat: no-repeat;


        }

        .content {

            position: absolute;
            top: 6%;
            left: 45%;
            transform: translate(0%, 1%);


            .normal-20-500 {
                color: colors.$gray-10;
                padding-top: 33px;
            }
        }

        .box-container {
            gap: 20px;
            flex-flow: unset !important;
            justify-content: center;


            .box-1,
            .box-2,
            .box-3 {
                max-width: 212px;
                border: solid 1px;
                border-radius: 12px;
                border-color: rgba(243, 243, 243, 1);
                height: 165px;
                gap: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 24px;

            }

            .box-2 {
                .rounded-background {
                    background-color: rgba(205, 234, 251, 0.5);

                    border-radius: 100%;
                }
            }
        }

        .welcome-button {
            justify-content: center;
            padding: 40px 0 56px 0;

            .welcome-button-style {
                width: 195px;
                height: 36px !important;
                background-color: colors.$blue-0;
            }
        }

    }
}

@media screen and (max-width: 768px) {

    .plan-name-style {
        font-weight: 700;
    }

    .form-side.welcome {
        justify-content: center;
    }

    .welcome-screen {
        display: flex;
        flex-direction: column;

        .background-image {
            background-image: url("../../../../common/assets/finishedRegister/congrats_mobile.svg");
            background-size: cover;
            background-position: center;
            height: 277px;
            width: 359px;
            position: relative;
            background-repeat: no-repeat;

        }

        .content {
            text-align: center;
            margin-top: 27px;
            margin-bottom: 15px;



            .normal-20-500 {
                display: none;
            }
        }

        .box-container {

            flex-direction: column;
            width: 100%;
            flex-wrap: nowrap;

            .box-1,
            .box-2,
            .box-3 {
                max-width: 100%;
                height: auto;
                border: solid 1px;
                border-radius: 12px;
                border-color: rgba(243, 243, 243, 1);
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 17px;
                text-align: center;
                margin: 0px 24px 16px 24px;

                :not(:last-child) {
                    margin-bottom: 16px;
                }


                .normal-16-600 {
                    margin-left: 13px;
                    padding-top: 10px;

                }


            }

            .box-3 {
                padding: 15px 10px 15px 10px;
            }

            .box-2 {
                .rounded-background {
                    background-color: rgba(205, 234, 251, 0.5);

                    border-radius: 100%;
                }
            }

            .normal-16-600 br {
                display: none;
            }
        }

        .welcome-button {
            flex-flow: column;
            margin: 50px 24px 30px 24px;

            .welcome-button-style {
                background-color: colors.$blue-0;
            }
        }
    }
}