@use "../../../../common/styles/colors" as colors;

.first-proposal-content-box {
    background-color: white;
    height: 527px;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    border: 1px #D9D9D9 solid;

    .first-text {
        line-height: normal !important;
        font-style: normal !important;
        text-align: center;
        padding-bottom: 24px;
    }
    .second-text {
        line-height: normal !important;
        font-style: normal !important;
        text-align: center;
        color: colors.$gray-2;
        max-width: 300px;
    }
    
    .plan-name-bold.normal-12 {
        font-weight: 700;
    }

    .content-buttons {
        justify-content: space-between;
        width: 100%;
        padding: 0 104px 28px;
    }
}