@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.input-edit-label-container {
    .input-edit-label {
        border: none !important;
        border-bottom: 1px  solid colors.$gray-1 !important;
        background-color: transparent;
        padding-bottom: 5px !important;
        padding-left: 5px !important;
        outline: none !important;
        color: black !important;
        min-width: 190px;
        font-weight: 600;
        font-size: 18px;
        color: black;
        opacity: 0.8;

        &::placeholder {
            font-weight: 600;
            font-size: 18px;
            color: black;
            opacity: 0.8;
        }

        &::-webkit-input-placeholder {
            /* Edge */
            font-weight: 600;
            font-size: 18px;
            color: black;
            opacity: 0.8;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer */
            font-weight: 600;
            font-size: 18px;
            color: black;
            opacity: 0.8;
        }

        &:disabled {
            background-color: transparent;
        }

        &:not(:disabled) {
            opacity: 0.6;
        }

        &:not(:disabled)::placeholder {
            background-color: transparent;
            // opacity: 0.2;
        }
    }

    span.pencil-icon {
        position: relative;
        top: 4px;
        left: 2px;

        &[disabled=""] {
            svg {
                color: colors.$gray-2 !important;

                path {
                    fill: colors.$gray-2 !important;
                }
            }
        }
    }
}
