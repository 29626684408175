@use "../../styles/colors";


.input-simple-container {
    position: relative;

    &.input-container-password input {
        padding-right: 50px;
    }

    .input-label {
        padding-right: 40px;
    }

    .suffix,
    .preffix {
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        transition: all 0.2s ease-in-out;
    }
    
    .preffix {
        left: 0;
        padding: 8px;
    }
    
    .suffix {
        right: 0;
        cursor: pointer;
        padding: 8px 12px 31px;
        transition: all 0.2s ease-in-out;
        &:hover {
            opacity: 0.6;
        }
        .icon-button{
            position: relative;
        }
    }

    .icon {
        font-size: 16px;
        color: colors.$gray-0;

        &.icon-close {
            color: colors.$red-0;
        }

        &.icon-checkmark {
            color: colors.$green-0;
        }
    }

    .icon-password {
        cursor: pointer;
        
    }

    .simple-input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        text-indent: 10px;
        font-weight: normal;
        box-sizing: border-box;
        border-radius: 4px !important;
        border: 1px solid colors.$gray-0 !important;
        
        &:disabled {
            color: colors.$gray-7 !important;
            cursor: not-allowed;
        }

        &[readonly] {
            background-color: white !important;
            color: colors.$gray-0 !important;
            cursor: auto;
        }
    
        &[data-editable="false"] {
            // background-color: colors.$gray-3 !important;
            cursor: not-allowed;
            border-bottom: none !important;
        }

        &::placeholder {
            font-size: 16px;
        }

        &.with-preffix {
            padding-left: 22px;
        }

        &.with-suffix {
            padding-right: 22px;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:focus-visible {
            border: 1px solid colors.$gray-0 !important;
            outline: none;
        }
    }
}

.ant-form-item-explain {
    font-size: 12px;
}

@media screen and (min-width: 768px) {
    .input-container {
        position: relative;

        &.input-container-password input {
            padding-right: 50px;
        }

        .input-label {
            padding-right: 40px;
        }

        .icon {
            font-size: 16px;
        }

        .simple-input {
            height: 40px;
            font-size: 16px;

            &::placeholder {
                font-size: 16px;
            }

            &.with-preffix {
                padding-left: 22px;
            }

            &.with-suffix {
                padding-right: 22px;
            }
        }
    }

    .ant-form-item-explain {
        font-size: 12px;
    }
}

@media screen and (min-width: 1024px) {
    .input-container {
        .simple-input {
            height: 50px;
            font-size: 18px;
        }
    }
}