@use '../../styles/colors' as colors;

.rounded-input {
    box-sizing: border-box;
    height: 50px;
    border: 1px solid colors.$gray-0 !important;
    border-radius: 28px !important;
    font-size: 14px;
    text-indent: 10px;
}

.ant-form-item-has-error .rounded-input,
.ant-form-item-has-error .rounded-input:hover,
.ant-form-item-has-error .rounded-input:focus {
    border-color: colors.$red-0 !important;
}

.rounded-input:focus {
    box-shadow: none !important;
}

.rounded-input:focus-visible {
    border: 1px solid colors.$blue-1 !important;
    outline: none;
}

.label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    color: colors.$black-0;
    line-height: 21px;
    margin-bottom: 6px;

    .error {
        color: colors.$red-0;
    }
}
