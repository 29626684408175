@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.ant-form {
    padding: 0;
    background-color: transparent;
}

.proposal-details,
.proposal-create-resume {

    .proposal-resume {
        background-color: colors.$gray-3;
    }

    .ant-row.row-title {
        @extend .normal-20;
        z-index: 1000;
        margin: 0 !important;
        font-weight: 500;
        line-height: 20px;
        background-color: white;
        border-radius: 8px 8px 0 0;
        padding: 15px 32px !important;
        cursor: pointer;

        &[data-is-active="false"] {
            margin-bottom: 12px !important;
            border-radius: 8px;
        }

        &[data-is-active="true"] {
            .title-resume {
                padding-top: 0px;
                opacity: 0;
                height: 0;
            }
        }

        &[data-allow-animation="true"][data-is-active="true"] {
            .title-resume {
                animation: animation-title-resume 0.9s;

                @keyframes animation-title-resume {
                    from {
                        padding-top: 5px;
                        opacity: 1;
                    }
            
                    to {
                        padding-top: 0px;
                        opacity: 0;
                        display: none;
                    }
                }
            }
        }

        &[data-is-last="true"] {
            margin-bottom: 0 !important;
        }

        .title-resume {
            @extend .normal-12;
            color: colors.$gray-2;
            padding-top: 5px;
            opacity: 1;
            display: block;
        }

        &.row-proposal-title {
            cursor: initial;
            background-color: colors.$gray-5;
            border-radius: 0;
        }
    }

    .ant-row.row-content {
        z-index: 900;
        padding: 20px 32px;
        margin: 0 !important;
        margin-bottom: 12px !important;
        background-color: white;
        border-radius: 0 0 8px 8px !important;
        display: flex;
        height: auto;
        
        &[data-is-active="false"] {
            padding: 0;
            height: 0;
            display: none;
        }

        &[data-is-last="true"] {
            margin-bottom: 0 !important;
        }
        
        &[data-allow-animation="true"][data-is-active="true"] {
            animation: animation-collapse-row 0.9s;

            @keyframes animation-collapse-row {
                from {
                    padding: 0;
                    display: flex;
                    opacity: 0;
                    height: 0;
                }
        
                to {
                    padding: 20px 32px;
                    display: flex;
                    opacity: 1;
                    height: auto;
                }
            }

        }
        

        .label-value {
            padding-bottom: 15px;
        }

        .label-value:last-child {
            padding-bottom: 0;
        }
    }


    .label-value {
        label {
            @extend .normal-12;
            color: colors.$gray-2;
        }

        div {
            @extend .normal-14-500;
            color: colors.$black-0;
        }
    }

    .label-value-edit-btn {
        @extend .normal-12;
        color: colors.$blue-0;
        cursor: pointer;

        svg {
            width: 14px;
            height: 14px;
            margin-right: 5px;
            position: relative;
            top: 2px;
            color: colors.$blue-0;

        }
    }

    .proposal-profile {
        .avatar {
            margin-right: 20px;

            img {
                height: 88px;
                width: 88px;
            }
        }

        .address {
            margin: 10px 0;
        }
    }

    .download-file {
        cursor: pointer;
        display: flex;
        height: 24px;
        align-items: center;
        justify-content: flex-start;
        margin: 9px 0px;

        &:hover {
            > div {
                color: colors.$blue-0 !important;
            }

            svg {
                color: colors.$blue-0;
            }
        }
        
        > div {
            @extend .normal-16;
            display: inline;
        }
  
        svg {
          width: 20px;
          height: auto;
          margin-left: 8px;
        }
      }
}
