// .button-add-absolut {
//   position: absolute;
//   left: calc(100% + 52px);
//   transform: translateY(-100%);
//   height: 100%;
// }

.row-select-bank-proposal {
  .button-add-option-on-select {
    margin: 0 0 5px;
  }

  .select-bank-proposal {
    .ant-select-selection-item {
      line-height: 40px !important;

      > div {
          line-height: 40px !important;

          > div  {
              display: inline;
          }

          > div:first-child::after {
              content: " | ";
          }
      }
    }
  }
}

.select-bank-option {
  &:not(:last-child) {
      border-bottom: 1px solid rgba(128, 128, 128, 0.086);
  }

  > div {
      line-height: 25px;
  }
}