@use "../../../../../../common/styles/colors" as colors;


.modal-description {
  padding-bottom: 8px;
}

.bank-selected-box {
  width: 100%;
  padding: 15px 0;
  text-align: left;
}

.search-bank {
  div {
    padding: 3px 0;
  }

  .search-bar {
    width: 100%;
  }
}

.list-bank {
  padding-top: 10px;
  max-height: 40vh;
  overflow: auto;

  .item-bank {
    padding: 10px;
    background-color: colors.$gray-3;
    border-bottom: 1px solid white;
    cursor: pointer;

    &:hover {
      background-color: colors.$gray-4;
    }
  }
}