@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";

.confirm-email-modal {
    .ant-modal-close {
        display: none;
    }

    .ant-modal-body {
        padding-bottom: 105px;

        .subtitle-modal {
            font-size: 16px;
            font-weight: 500;
            margin-top: 27px;
        }
    }
}

.list-proposal-page {
    .white-box,
    .blue-box {
        width: 100%;
        height: 67px;
        background-color: colors.$white-2;
        padding: 0;
        line-height: 55px;
        padding: 8px 24px;

        .box-label {
            @extend .normal-14;
            color: var(--primary-blue);
        }

        .box-value {
            @extend .normal-20;
            font-weight: 500;
            line-height: 55px;
            color: var(--primary-blue);
            text-align: center;
        }
    }

    .blue-box {
        background-color: var(--primary-blue);

        .box-label {
            color: white;
        }

        .box-value {
            color: white;
        }
    }

    .proposals-empty-card {
        display: flex;
        min-height: 269px;
        margin-top: 12px;
        text-align: center;
        border-radius: 8px;
        align-items: center;
        color: colors.$gray-2;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        padding: 48px 0 64px !important;

        img {
            width: 152px;
        }

        .message {
            max-width: 286px;
            margin-bottom: 19px;
        }

        .blue-button {
            // height: 50px !important;
            // margin: 0 0 15px !important;

            //     .loading-container {
            //         position: relative;
            //         top: 16px;
            //     }
        }
    }

    .share-link {
        .ant-col:first-child {
            padding: 10px;
            background-color: colors.$gray-6;
            border-radius: 5px;
        }

        .ant-col:last-child {
            padding: 18px 0;
            text-align: center;

            svg {
                width: 20px;
                height: auto;
                color: colors.$gray-2;

                path {
                    fill: colors.$gray-2;
                }
            }
        }
    }

    .proposals-header {
        @extend .normal-14;
        margin-top: 10px;
        background-color: white;
        padding: 10px 15px !important;

        .ant-col {
            text-align: left;
        }

        .config-filters-icon {
            cursor: pointer;
            position: relative;
            top: 3px;
        }
    }

    .resend-contract-modal {
        .loading-container {
            position: relative;
            left: -10px;
        }
    }

    .loading-container {
        width: 0;
        height: 100px;
    }
    
}

.ant-dropdown-menu.config-menu {
    width: 300px;
    padding: 22px 0;

    .ant-dropdown-menu-item {
        padding: 5px 18px;
        background-color: #fff !important;

        &.title-menu,
        &.subtitle-menu {
            opacity: 1;
            cursor: auto;
            color: #000;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 2px;
        }

        &.add-tag {
            cursor: auto;
            background-color: white;

            .junction {
                display: flex;
                cursor: pointer;
                align-items: center;
            }

            &:hover {
                background-color: transparent;
            }

            .ant-dropdown-menu-title-content {
                display: flex;
                color: var(--primary-blue);

                .anticon-plus {
                    width: 24px;
                    display: flex;
                    justify-content: flex-start;
                    svg {
                        font-size: 16px;
                        path {
                            fill: var(--primary-blue) !important;
                        }
                    }
                }

                .text-new-tag {
                    font-size: 14px;
                    text-decoration: underline;
                }
            }

            .input-add-tag {
                width: 85%;
                height: 30px;
                border: none;
                outline: none;
                color: #000;
                border-bottom: 1px solid colors.$gray-0;
            }

            .anticon-loading {
                width: 15%;
                height: 22px;
                margin-top: 0;
                font-size: 20px;

                svg {
                    fill: var(--primary-blue);
                }
            }
        }

        &.subtitle-menu {
            margin-bottom: 0;
            font-weight: normal;

            .ant-dropdown-menu-title-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }

        &.menu-item-tags,
        &.title-menu {
            padding: 0 18px;
        }

        &.menu-item-tags {
            display: flex;
            align-items: flex-start;
            background-color: transparent;

            &.scroll {
                overflow: hidden;
                overflow-y: auto;
                max-height: 210px;
            }

            .ant-dropdown-menu-title-content {
                display: flex;
                padding-right: 6px;
                flex-direction: column;
                height: auto !important;

                .menu-item-tag {
                    width: 100%;
                    cursor: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }

    .ant-dropdown-menu-item-divider {
        margin: 29px 18px;
    }
}

.item-options {
    .option-name {
        width: 100%;
        display: flex;
        cursor: pointer;
        font-weight: 500;
        justify-content: space-between;
        transition: all 0.2s ease-in-out;
        

        &:hover {
            color: var(--primary-blue);

            .arrow::before,
            .arrow::after {
                background-color: var(--primary-blue);
            }
        }

        .arrow {
            position: relative;

            &::before,
            &::after {
                content: "";
                top: 10px;
                width: 8px;
                height: 1px;
                position: absolute;
                display: inline-block;
                background-color: #000;
                transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                -ms-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
            }

            &::before {
                left: 0;
                transform: rotate(-45deg);
            }

            &::after {
                right: -3px;
                transform: rotate(45deg);
            }

            &.open {
                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .ant-dropdown-menu-title-content {
        display: flex;
        flex-direction: column;

        .options {
            display: flex;
            max-height: 0;
            overflow: hidden;
            flex-direction: column;
            transition: all 0.2s ease-out;
            -o-transition: all 0.2s ease-out;
            -ms-transition: all 0.2s ease-out;
            -moz-transition: all 0.2s ease-out;
            -webkit-transition: all 0.2s ease-out;

            &.open {
                max-height: 300px;
                transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                -ms-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                -webkit-transition: all 0.2s ease-in;
            }
        }
    }
}
