@use "../../common/styles/colors"as colors;

.topbar-root {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.topbar-container {
    $icon-color: var(--primary-blue);
    $icon-color-active: var(--primary-blue-hover);
    $icon-color-hover: var(--primary-blue-hover);
    $icon-color-contrast: colors.$white-2;


    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 1090px;
    padding: 0 12px;

    .bell-alert-icon {
        cursor: pointer;

        svg {
            height: 16px;
            width: auto;
        }
    }

    .junction-switch-mode {
        height: 36px;
        display: flex;
        margin-right: 26px;
        align-items: center;
        // width: 100%;
        // background-color: blue;

        .ant-switch {
            margin-right: 11px;
            width: 34px !important;
            height: 19px !important;
            min-width: 34px !important;

            .ant-switch-handle,
            .ant-switch-handle:before {
                top: 1px !important;
                left: 1px !important;
                width: 15px !important;
                height: 15px !important;
            }

            &.ant-switch-checked {
                min-width: 34px !important;

                .ant-switch-handle {
                    top: 1px !important;
                    width: 15px !important;
                    height: 15px !important;
                    left: calc(100% - 18px) !important;
                }
            }
        }

        span {
            font-size: 18px;
            font-weight: 500;
        }

        .circle-help-icon {
            height: 24px;
            margin-left: 19px;

            svg {
                width: 16px;

                path {
                    color: colors.$gray-2;
                }
            }
        }
    }

    .bell-icon {
        cursor: pointer;

        svg {
            height: 16px;
            width: auto;
            color: colors.$gray-2;

            path {
                fill: colors.$gray-2;
            }
        }
    }

    .logo {
        color: colors.$blue-1;
        font-size: 50px;
        font-weight: 600;
        margin-top: 15px;
        margin-left: 15px;
    }

    .topbar-container-left {
        display: flex;
        align-items: center;
        color: var(--primary-blue);

        .icon-navbar {

            svg {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                color: $icon-color;
                justify-content: center;

                path {
                    fill: $icon-color;
                }
            }
            .reports-icon {
                svg {
                    path:first-child {
                        fill: transparent !important;
                        stroke: $icon-color !important;
                    }
                }
            }
            .home-icon {
                svg {
                    width: 40px !important;
                    height: 40px !important;

                    path {
                        width: 21px;
                        height: 21px;
                    }

                }
            }

            .benefits-icon {
                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }


    }

    .topbar-container-right {
        display: flex;
        align-items: center;

        .settings-icon,
        .bell-icon,
        .bell-alert-icon {
            height: 20px;
            transition: all 0.2s ease-in-out;

            svg {
                height: 20px;
                font-size: 20px;

                path {
                    fill: colors.$gray-2;
                }
            }

            &:hover {
                opacity: 0.6;
            }
        }

        .bell-alert-icon svg {
            height: 20px;
            font-size: 20px;

            path#propozall-bell-dot-icon-svg-3 {
                fill: red;
            }
        }

        .settings-icon {
            margin-right: 32px;
        }

    }
}

.justify-center {
    justify-content: center;
}