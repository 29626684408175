.box-link {
  background-color: #f3f3f3;
  padding: 2px 15px;
  border-radius: 2px;
  margin-right: 15px;

  a {
    color: var(--primary-blue) !important;
  }

  .anticon-close {
    margin-left: 15px;
  }
}
