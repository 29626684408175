@use "../../styles/colors" as colors;
@use "../../styles/app.scss";
@use "../../../features/proposal/components/ProposalResume/styles.scss";

.gray-button {
  @extend .normal-14-500;

  color: colors.$gray-2;
  border: 1px solid colors.$gray-2;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 14px;
  // width: 180px;

  .loading-container {
    width: 20px;
    display: inline-block;
    position: relative;
    top: 5px;
    opacity: 0.5;
    filter: grayscale(100%);
  }

  label {
    padding-left: 10px;
    cursor: pointer;
  }

  .sign-icon {
    float: left;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: colors.$gray-2;
      }
    }
  }
}

.proposal-header-actions {
  width: 100% !important;
  height: 0;
  position: relative;
  top: -65px;
}

.proposal-details {
  .blue-button,
  .white-button {
    min-width: 208px;
    // padding: 0 40px;
    // height: 40px;

    // .button-loading {
    //   .loading-container {
    //     position: relative;
    //     left: -30px !important;
    //   }
    // }
  }

  .main-content-top {
    height: 12px;
    width: 100%;
    background-color: colors.$blue-0;
    border-radius: 8px 8px 0 0;
  }

  .main-content {
    padding: 0 0 20px 0 !important;
    background-color: transparent !important;

    @extend .proposal-resume;

    .item-history {
      padding: 5px 0px;
    }

    .row-proposal-title {
      margin: 0 !important;
      // padding: 12px;

      .proposal-title {
        line-height: initial !important;
      }

      .proposal-status-col {
        @media (max-width: 768px) {
          text-align: left;
        }

        @media (min-width: 768px) {
          text-align: center;
        }
      }

      .proposal-created-at {
        // line-height: 5px;
        @media (max-width: 768px) {
          text-align: left;
        }

        @media (min-width: 768px) {
          text-align: right;
        }

        span.share-icon {
          padding-left: 8px;
          cursor: pointer;
          position: relative;
          top: 4px;

          svg {
            height: 22px;
            width: auto;
            color: colors.$gray-2;

            path {
              fill: colors.$gray-2 !important;
            }
          }
        }
      }
    }
  }

  .contract-file {
    @extend .normal-14;

    .gray-button {
      .sign-icon {
        float: left;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: colors.$gray-2;
          }
        }
      }
    }
  }

  .method-types {
    .info {
      padding: 0 10px;
    }

    &:first-child .info {
      padding-left: 0;
    }

    &:last-child .sep {
      display: none;
    }
  }

  .file-container {
    .download-file {
      display: flex;
      height: 24px;
      align-items: center;
      justify-content: flex-start;
      margin: 9px 0px;

      > div {
        display: inline;
      }

      svg {
        width: 20px;
        height: auto;
        margin-left: 8px;
      }
    }

    .ant-image-img,
    video {
      box-shadow: 0 2px 5px 5px rgba(242, 242, 242, 0.2),
        0 1px 10px 0 rgba(228, 228, 228, 0.12),
        0 4px 8px 0 rgba(183, 183, 183, 0.14) !important;
    }
  }
}

.upload-contract-modal {
  padding-bottom: 0px !important;

  .ant-modal-body {
    padding-bottom: 0px !important;
  }

  .box-required-text {
    width: 100%;
    margin: 15px 0;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid colors.$gray-0;
  }

  .actions {
    width: 100%;

    .white-button {
      height: 40px;
    }
  }

  .file-info {
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }

  input[type="file"] {
    display: none;
  }
}

div#row-content-logotype {
  flex-direction: column;

  span.ant-upload.ant-upload-btn {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .ant-upload.ant-upload-drag.file-upload-box {
    border-radius: 8px !important;
    border: 1px solid #d9d9d9;
    background: white;
    height: 128px;

    .ant-upload-drag-container {
      display: flex !important;

      .ant-row.ant-row-center.label {
        color: #000;
      }

      .ant-row.ant-row-center.icon {
        padding: 0;

        svg {
          width: 24px;
          height: 24px;

          path {
            fill: #0038d1 !important;
          }
        }
      }
    }
  }

  // width: 100%;
  // height: 128px;
  // border-radius: 8px;
  // border: 1px solid #d9d9d9;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    color: #0038d1;
  }
  input#upload {
    display: none;
  }
}
