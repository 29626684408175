@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.box-qrcode-pix {
    width: 100%;
    .box-qrcode-pix {
        text-align: center;
    }
    
    .box-codigo-pix {
        text-align: center;

        > div:first-child {
            display: inline-block;
            overflow-wrap: anywhere;
            text-align: left;
            width: 90%;
            margin: 15px 0 !important;
            padding: 15px;
            border-radius: 5px;
            border: 1px solid colors.$gray-11;
            font-size: 12px !important;
        }

        > div:last-child {
            color: colors.$blue-0;

            .icon-copy {
                margin-right: 5px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}