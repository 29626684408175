@use "../../../../common/styles/colors"as colors;

.upload-nfse-page {
    .ant-form {
        margin-top: 56px;

        .rouded-box {
            width: 100%;
            display: flex;
            font-size: 18px;
            cursor: pointer;
            margin-top: 26px;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 32px;
            padding: 32px 32px 16px;
            background-color: #FFF;
            justify-content: space-between;

            &.selected {
                color: #FFF;
                background-color: var(--primary-blue);

                &:hover {
                    background-color: var(--primary-blue);
                }
            }

            .ant-form-item {
                width: 49%;
            }
        }
    }

    .item-select-file.ant-upload {
        height: 189px;
        display: flex;
        border-radius: 8px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 2px dashed #BFCDF3;
        background: rgba(217, 225, 248, 0.1);


        #choose-nfse-file {
            height: 0;
            opacity: 0;
        }

        .select-file {
            text-align: center;

            button {
                border: none;
                display: flex;
                cursor: pointer;
                padding: 8px 40px;
                align-items: center;
                margin: 0 auto 12px;
                justify-content: center;
                background-color: #FFF;
                color: var(--primary-blue);
                transition: all 0.2s ease-in-out;

                .file-icon {
                    top: -5px;
                    height: 26px;
                    margin-right: 8px;
                    position: relative;

                    svg path {
                        fill: var(--primary-blue);
                    }
                }

                &:hover {
                    opacity: .8;
                }
            }

            .text {
                top: 0;
                font-size: 12px;
                text-align: center;
            }
        }

        .selected-file {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primary-blue);
            .file-icon {
                top: 2px;
                position: relative;
                svg {
                    height: 26px;
                    path {
                        fill: var(--primary-blue);
                    }
                }
            }

            .anticon {
                margin-top: -2px;
                padding: 8px 12px;
                color: colors.$black-0;
                transition: all 0.2s ease-in-out;
                &:hover {
                    opacity: .6;
                }
            }
        }
    }

    .ant-upload-list {
        display: none;
    }

    .text-alert {
        font-size: 12px;
        margin: 16px 0 10px;
        color: var(--primary-blue);

        .alert-icon {
            margin-right: 8px;

            svg {
                height: 16px;
                fill: var(--primary-blue);
            }
        }
    }

    .text-error {
        bottom: 12px;
        position: relative;
        color: colors.$red-0;
    }
}