.password-validation-container {
    .pvc-title {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 4px;
    }
    
    .validations {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;   

        span {
            width: 50%;
            font-size: 12px;
        }
    
        .checked {
            text-decoration: line-through;
        }
    }
}

@media screen and (min-width: 768px) {
    .password-validation-container {
        max-width: 440px;
        .pvc-title {
            font-size: 14px;
            margin-top: 12px;
            margin-bottom: 6px;
        }
        
        .validations {
            span {
                font-size: 13px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .password-validation-container {
        max-width: 345px;
        
        .validations {
            span {
                font-size: 12px;
            }
        }
    }
}