.create-nfse-page {
    width: 100%;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1090px;
    padding: 0 12px 20px;
    flex-direction: column;

    .back-button {
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 26px;
        .anticon {
            margin-right: 8px;
        }
    }
    
    .ant-select-selector {
        padding-left: 5px !important;
    }

    .flatted-input {
        height: 40px;
        width: 100%;
    }
    
    .buttons {
        width: 100%;

        button {
            min-width: 35%;
            height: 40px;
            max-width: 246px;
            margin-top: 16px;
            margin-left: 16px;

            &.outlined-button {
                color: var(--primary-blue);
                border-color: var(--primary-blue);
            }
        }
    }
}