.modal-progress-download {
    padding: 16px;
    border: 2px solid rgb(50, 88, 193);
    border-radius: 8px;
    background-color: #fff !important;
    margin-bottom: 10px;
    margin: 0 0 10px;



    .download-label {
        font-size: 12px;
        font-weight: 400;
        color: black;
        padding-bottom: 15px;

        .download-text-label {
            font-size: 12px;
            font-weight: 400;
            color: #1D945A;
            justify-content: center;
            display: flex;


            svg {
                width: 20px;
                height: 20px;
            }

        }

    }

    .fileName {
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 7px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        display: block;
    }

    .progress-row {
        display: flex;
        justify-content: space-between;

        .time-download {
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
        }

        .cancel-download {
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            color: #002999;
        }
        .completed-label {
            font-size: 12px;
            font-weight: 600;
            color: #1D945A;
        }
    }

    .divider {
        .divider-item {
            background-color: rgba(217, 217, 217, 1);
            height: 1px;
        }
    }

    .completed-exported-row {

        .completed-filename {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 7px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
            display: block;
            padding-bottom: 12px;
        }

        
    }

}