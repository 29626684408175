// @use "../../../../common/styles/_colors";

// @media screen and (max-width: 768px) {
//     .container-contract {
//         padding: 10px 8px 10px 10px;
//     }
// }

.ant-modal.software-contract-modal {
    #terms-privacy-iframe {
        max-height: 60vh !important;
        padding: 0 !important;
    }

    @media screen and (min-width: 768px) {
        width: 60vw !important;
        .ant-modal-content {
            padding: 24px 50px;
        }
        .ant-modal-footer {
            .ant-row {
                // width: 100% !important;
                // text-align: center !important;
                // align-items: center !important;
                // justify-content: center !important;

                .ant-col {
                    max-width: 250px;
                }
            }
        }
    }
}

// .contract-page {
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100vh;
//     display: flex;
//     position: fixed;
//     z-index: 9999999999;
//     align-items: center;
//     justify-content: center;
//     background-color: rgba(0, 0, 0, 0.6);

//     /* width */
//     ::-webkit-scrollbar {
//         width: 5px;
//         height: 90%;
//         border-radius: 4px;
//     }

//     /* Track */
//     ::-webkit-scrollbar-track {
//         background: #d9d9d9;
//     }

//     /* Handle */
//     ::-webkit-scrollbar-thumb {
//         cursor: pointer;
//         background-color: #000;
//         transition: all 0.2s ease-in-out;
//     }

//     /* Handle on hover */
//     ::-webkit-scrollbar-thumb:hover {
//         background: rgba(0, 0, 0, 0.471);
//     }

//     .container-contract {
//         width: 85%;
//         height: 85vh;
//         z-index: 9999;
//         display: flex;
//         border-radius: 15px;
//         flex-direction: column;
//         background-color: #f5f5f5;
//         padding: 20px 8px 20px 20px;
//         justify-content: space-between;

//         .content {
//             height: calc(100% - 60px);
//             display: flex;
//             overflow: auto;
//             position: relative;
//             padding-right: 20px;
//             flex-direction: column;
//             box-sizing: border-box;
//             align-items: flex-start;

//             .contract-title {
//                 font-size: 16px;
//                 font-weight: bold;
//                 margin-bottom: 12px;
//                 text-transform: uppercase;
//             }

//             .contract-subtitle {
//                 font-size: 13px;
//                 font-weight: 500;
//                 margin-bottom: 12px;
//             }

//             .contract-text {
//                 font-size: 12px;
//                 margin-bottom: 32px;
//                 text-align: left;
//             }
//         }

//         .blue-button {
//             width: auto;
//             height: auto;
//             font-size: 12px;
//             margin: 0 auto 0 0;
//             padding: 8px 16px !important;

//             &:hover {
//                 opacity: 0.7;
//             }
//         }
//     }
// }

// @media (min-width: 768px) {
//     .contract-page {
//         /* width */
//         ::-webkit-scrollbar {
//             width: 6px;
//         }

//         .container-contract {
//             height: 90vh;
//             padding: 30px 8px 30px 30px;

//             .content {
//                 padding-right: 30px;

//                 .ant-checkbox-wrapper {
//                     margin: 40px auto 20px;

//                     .ant-checkbox {
//                         margin-top: -6px;
//                     }
//                 }
//             }

//             .blue-button {
//                 width: auto;
//                 height: auto;
//                 font-size: 15px;
//                 padding: 10px 26px;
//             }
//         }
//     }
// }

// @media (min-width: 1024px) {
//     .contract-page {
//         /* width */
//         ::-webkit-scrollbar {
//             width: 6px;
//         }

//         .container-contract {
//             height: 90vh;
//             padding: 46px 32px 46px 46px;

//             .content {
//                 padding-right: 46px;
//                 height: calc(100% - 70px);

//                 .contract-title {
//                     font-size: 32px;
//                     font-weight: 500;
//                     margin-bottom: 28px;
//                 }

//                 .contract-subtitle {
//                     font-size: 24px;
//                     font-weight: 400;
//                     margin-bottom: 16px;
//                 }

//                 .contract-text {
//                     font-size: 14px;
//                     text-align: left;
//                     font-weight: 400;
//                     line-height: 21px;
//                     margin-bottom: 28px;
//                     letter-spacing: 0.3px;
//                 }
//             }
//             .blue-button {
//                 font-size: 16px;
//                 padding: 13px 37px;
//             }
//         }
//     }
// }

// @media (min-width: 1440px) {
//     .contract-page {
//         ::-webkit-scrollbar {
//             width: 12px;
//         }

//         .container-contract {
//             height: 90vh;
//             max-width: 1270px;
//             padding: 30px 8px 30px 30px;

//             .content {
//                 padding-right: 30px;
//             }

//             .blue-button {
//                 width: auto;
//                 height: auto;
//                 font-size: 15px;
//                 padding: 10px 26px;
//             }
//         }
//     }
// }
