@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.plan-use-plan {
    @extend .normal-12-700;
    background-color: colors.$blue-6;
    padding: 4px 16px;
    border-radius: 16px;
    text-transform: uppercase;
    color: white;
    margin-left: 10px;
    min-width: 70px;
    text-align: center;
}

.plan-use-modal {
    // width: 100%;
    // height: 100%;
    display: flex;
    position: fixed;
    z-index: 1000;
    justify-content: center;
    // margin-top: 90px;
    right: 0;
    top: 90px;
    left: 0;
    overflow: hidden;

    .back-modal {
        background-color: red;
        position: fixed;
        top: 90px;
        right: 0;
        bottom: 0;
        left: 64px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        animation-duration: 0.5s;
        animation-name: animate-fade;
        // animation-delay: 0.5s;
        animation-fill-mode: both;
    }

    @keyframes animation-plan-use-box {
        from {
            height: 0;
            padding: 0;
        }

        to {
            height: 203px;
            padding: auto;
        }
    }

    @keyframes appear-content-plan-use-box {
        from {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .plan-use-box {
        width: 360px;
        background-color: white;
        z-index: 1001;
        position: fixed;
        border-radius: 0px 0px 8px 8px;
        padding: 18px;
        animation: animation-plan-use-box 0.6s;

        > * {
            animation: appear-content-plan-use-box 0.6s;
        }

        .ant-divider {
            margin: 18px 0 !important;
        }
    }

    .plan-use-usage {
        @extend .normal-12-500;
        color: colors.$blue-6;
        margin-right: 5px;
    }

    .plan-use-usage-description {
        @extend .normal-12;
        color: black;
    }

    .plan-use-panel > .ant-collapse-header {
        padding: 0 !important;

        .ant-collapse-arrow {
            right: 0px !important;
        }
    }

    .plan-use-panel > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
    }
}

@keyframes animate-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
