@use '../../styles/colors' as colors;
@use '../../styles/app.scss';

.circle-check-box {
  height: 30px;
  line-height: 30px;

  .ant-checkbox .ant-checkbox-inner {
    transform: none !important;
    transition: none !important;
    border-radius: 50%;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background: url("../../assets/circle_check_outline.svg") no-repeat;
    background-position: right;
    margin-right: -4px;
    position: relative;
    top: 2px;
    border: none !important;
  }

  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    opacity: 0.3 !important;
  }

  *:hover,
  *::after,
  *::before {
    border: none !important;
  }
}
