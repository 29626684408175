.tag-status {
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  border-color: transparent;
  padding: 0;

  &[data-status="draft"] {
    color: #555555;
    // background: rgba(85, 85, 85, 0.1);
    // border-color: #555555;
  }

  &[data-status="refused"] {
    color: #202020;
    // background: rgba(85, 85, 85, 0.1);
    // border-color: #555555;
  }

  &[data-status="waiting_review"],
  &[data-status="waiting_accept"] {
    color: #cf9500;
    // background: rgba(247, 178, 5, 0.1);
    // border-color: #f7b205;
  }

  &[data-status="accepted"] {
    color: #1d945a;
    // background: rgba(29, 148, 90, 0.1);
    // border-color: #1d945a;
  }

  &[data-status="expired"] {
    color: #8c0100;
    // background: rgba(140, 1, 0, 0.1);
    // border-color: #8c0100;
  }

  &[data-status="canceled"] {
    color: #e90000;
    // background: rgba(233, 0, 0, 0.1);
    // border-color: #e90000;
  }

  &[data-status="waiting_sign_contract"] {
    color: #3300ac;
    // background: rgba(51, 0, 172, 0.1);
    // border-color: #3300ac;
  }

  &[data-status="waiting_nfs"] {
    color: #0038d1;
    // background: rgba(0, 56, 209, 0.1);
    // border-color: #0038d1;
  }

  &[data-status="finalized"] {
    color: #074929;
    // background: rgba(7, 73, 41, 0.1);
    // border-color: #074929;
  }
}
