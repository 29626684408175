@use "../../../../common/styles/colors";


.stepper-container {
    width: 100%;
    height: 55px;
    display: flex;
    max-width: 600px;
    position: relative;
    margin: 100px auto 40px;
    justify-content: space-between;

    .stepper-line {
        left: 50%;
        top: 16px;
        width: 70%;
        height: 1px;
        position: absolute;
        transform: translateX(-50%);
        background-color: colors.$gray-1;
    }

    .container-step {
        width: 33%;
        display: flex;
        justify-content: center;

        &.pointer .step-circle-unit {
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                filter: grayscale(.2)
            }
        }

        &.default .step-circle-unit {
            cursor: default;
        }

        .step-circle-unit {
            display: flex;
            text-align: center;
            align-items: center;
            flex-direction: column;
        }

        .step-circle {
            width: 32px;
            height: 32px;
            z-index: 999;
            display: flex;
            font-size: 20px;
            font-weight: 600;
            border-radius: 50%;
            align-items: center;
            color: colors.$white-0;
            justify-content: center;
        }


        .step-label {
            font-size: 10px;
            margin-top: 4px;
        }

        .step-circle-active {
            background-color: colors.$blue-0;
        }

        .step-circle-non-active {
            color: colors.$black-0;
            background-color: #fff;
            border: 1px solid rgba(0, 27, 102, 0.7);
        }
    }
}

@media screen and (min-width: 768px) {
    .stepper-container {
        max-width: 768px;
        position: relative;
        margin: 190px auto 40px;

        .stepper-line {
            top: 16px;
        }

        .container-step {
            .step-circle {
                width: 40px;
                height: 40px;
                font-size: 20px;
                font-weight: 600;
            }

            .step-label {
                font-size: 12px;
                margin-bottom: -8px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .stepper-container {
        max-width: 668px;
        margin: 60px auto;
        position: relative;

        .stepper-line {
            top: 24px;
        }

        .container-step {
            .step-circle {
                width: 48px;
                height: 48px;
                font-size: 24px;
            }

            .step-label {
                font-size: 14px;
                margin-bottom: -50px;
            }
        }
    }

}