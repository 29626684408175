@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.favorite-icon {
  svg {
    width: 20px;
    height: auto;
    color: colors.$gray-2;
  }

  &.favorited {
    svg {
      color: var(--primary-blue);
    }
  }
}
