@use "../../common/styles/colors" as colors;

.proposal-view-page {
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  max-width: 1090px;
  .back-page-btn {
    display: flex;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    margin-bottom: 19px;
    color: colors.$gray-2;
    transition: all 0.2s ease-in-out;
    .arrow-right-icon {
      width: 20px;
      height: 32px;
      margin-right: 15px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .proposals-empty-card {
    display: flex;
    min-height: 269px;
    margin-top: 12px;
    text-align: center;
    border-radius: 8px;
    align-items: center;
    color: colors.$gray-2;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 48px 0 64px !important;

    img {
        width: 152px;
    }

    .message {
        max-width: 286px;
        margin-bottom: 19px;
    }

    .blue-button {
        // height: 50px !important;
        // margin: 0 0 15px !important;

        //     .loading-container {
        //         position: relative;
        //         top: 16px;
        //     }
    }
}
}
