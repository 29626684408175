@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.card-proposal {
  width: 100%;
  display: flex;
  padding: 16px;
  cursor: pointer;
  min-height: 95px;
  margin-top: 12px;
  border-radius: 8px;
  align-items: center;
  color: colors.$gray-2;
  justify-content: space-between;
  background-color: colors.$white-2;
  transition: all 0.2s ease-in-out;
  border-bottom: 3px solid transparent;
  // box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2), 0 4px 8px 0 rgba(228, 228, 228, 0.2);

  &:hover {
    border-color: var(--primary-blue);
  }


  .box-proposal-title {
    width: 100%;
    text-align: left;
    // position: relative;
    // left: 16px;
    padding-left: 16px;
    max-width: 170px;

    .info-date-time {
      @extend .normal-14;
        color: colors.$gray-7;

        svg {
          path {
            stroke: colors.$gray-7;
          }
        }
    }

    .proposal-avatar {
      img {
        width: 50px;
        height: 50px;
      }
    }

    .proposal-title {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      color: colors.$black-0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
    }
  
    .proposal-counterparty {
      position: relative;
      top: 4px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      color: colors.$gray-2;
    }
  }

  .proposal-chat-messages {
    .messages-icon {
      position: relative;
      left: 5px;
      
      svg {
        width: 30px !important;
        height: 30px !important;

        path {
          fill: colors.$gray-0;
        }
      }

      &[data-active="true"] {
        svg path {
          fill: #0018e8;
        }
      }
    }

    .ant-badge-count {
      background-color: #0018e8;
      width: 20px;
      height: 20px;
      min-width: none;
      padding: 0;
    }
  }

  .col-tags {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }


  .ant-col {
    text-align: left;
  }

  .more-button {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    cursor: pointer;

    span {
      height: 24px;
      width: 24px;

      svg {
        transform: rotate(90deg);
      }
    }
  }

  .more-button:hover {
    background-color: colors.$gray-6;
  }
}

.tag-new-container {
  height: 0 !important;
  width: 100%;
  text-align: right;

  .tag-new,
  .tag-counterparty {
    @extend .normal-14-500;
    font-size: 8px;
    font-weight: 900;
    line-height: 17px;
    padding: 1px 8px;
    border-radius: 20px;
    background-color: var(--primary-blue);
    color: white;
    display: inline;
    margin: 0 10px;
    text-transform: uppercase;
    position: relative;
    top: 12px;
  }

  .tag-counterparty {
    background-color: colors.$red-1;
  }
}

.card-proposal-menu.ant-dropdown-menu {
  padding: 0;
  width: 315px;
  border-radius: 8px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 1px rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14) !important;

  .ant-dropdown-menu-title-content {
    @extend .normal-16;
    height: 40px;
    display: flex;
    line-height: 35px;
    align-items: center;
  }

  .ant-dropdown-menu-item {
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    align-items: center;

    &.disabled-tag {
      opacity: 1;
      cursor: auto;
    }

    &.menu-item-tags {
      display: flex;
      align-items: flex-start;
      background-color: transparent;

      &.scroll {
        overflow: hidden;
        overflow-y: auto;
        max-height: 240px;
      }

      .ant-dropdown-menu-title-content {
        display: flex;
        padding-right: 6px;
        flex-direction: column;
        height: auto !important;

        .menu-item-tag {
          width: 100%;
          cursor: auto;
          display: flex;
          padding: 8px 0;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          &.blocked {
            cursor: not-allowed;
          }

          &:hover {
            .delete-icon {
              display: block;
            }
          }

          .delete-icon {
            display: none;
            height: 25px;
            padding: 0 4px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            svg path {
              fill: colors.$gray-2;
            }

            &:hover {
              opacity: 0.6;
            }
          }
        }
      }

      &:hover {
        background-color: transparent;
      }
    }

    &.add-tag {
      cursor: auto;
      background-color: white;

      .junction {
        display: flex;
        cursor: pointer;
        align-items: center;
      }

      &:hover {
        background-color: transparent;
      }

      .ant-dropdown-menu-title-content {
        color: var(--primary-blue);

        svg path {
          fill: var(--primary-blue) !important;
        }

        .text-new-tag {
          font-size: 14px;
          text-decoration: underline;
        }
      }

      .input-add-tag {
        width: 85%;
        height: 30px;
        border: none;
        outline: none;
        color: #000;
        margin-bottom: 15px;
        border-bottom: 1px solid colors.$gray-0;
      }

      .anticon-loading {
        width: 15%;
        margin-top: -15px;
        font-size: 20px;

        svg {
          fill: var(--primary-blue);
        }
      }
    }

    .icon-menu {
      span {
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          width: 22px;

          path {
            fill: colors.$gray-2 !important;
          }
        }
      }
    }

    span.duplicate-icon {
      top: 4px;
    }
  }

  .ant-dropdown-menu-item-disabled {
    opacity: 0.5;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0;
  }
}
