@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.load-more-paginator {
  width: 100%;
  padding: 20px 0;
  text-align: center;

  .loading-container {
    text-align: center;

    .loading {
      margin-left: -10px;
    }
  }

  .icon-load-more {
    svg {
      width: 20px;
      height: auto;
      color: colors.$gray-2;
    }
  }
}
