@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.date-picker-container {
  &.floating {
    .date-picker-calendar {
      position: absolute;
      box-shadow: 0 2px 5px 5px rgba(242, 242, 242, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.12),
        0 4px 8px 0 rgba(183, 183, 183, 0.14);
        z-index: 1000;
    }
  }

  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: " ";
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    .date-picker-calendar {
      position: absolute;
      top: 30vh;
      left: 40vw;
      box-shadow: 0 2px 5px 5px rgba(242, 242, 242, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.12),
        0 4px 8px 0 rgba(183, 183, 183, 0.14);
    }
  }

  .date-picker-calendar {
    padding: 10px 20px;
    background-color: white;
    border: 1px solid colors.$gray-3;
    border-radius: 8px;

    .react-calendar {
      max-width: 200px;
    }

    .react-calendar__navigation {
      display: flex;
      flex-direction: row;

      .react-calendar__navigation__label {
        @extend .normal-12-500;
        color: colors.$gray-2;
        background-color: transparent;
        border: none;
        text-align: left;
        position: relative;
        left: -4px;
        order: 1;

        &:first-letter {
          text-transform: capitalize;
        }
      }

      .react-calendar__navigation__arrow {
        background-color: transparent;
        border: none;
        color: var(--primary-blue);
        font-size: 23px;

        // Remove next and previous year buttons
        &.react-calendar__navigation__next2-button,
        &.react-calendar__navigation__prev2-button {
          display: none;
        }

        &.react-calendar__navigation__prev-button {
          order: 2;
        }

        &.react-calendar__navigation__next-button {
          order: 3;
        }
      }
    }

    // calendar with view selecting months
    .react-calendar__year-view {
      .react-calendar__year-view__months {
        .react-calendar__year-view__months__month {
          &.react-calendar__tile--active {
            @extend .normal-12-500;
            font-size: 11px;
            font-weight: 600;
            background-color: var(--primary-blue);
            color: white;
            line-height: 24px;
          }
        }
      }
    }

    // calendar with view selecting days
    .react-calendar__month-view {
      // weekdays top bar
      .react-calendar__month-view__weekdays {
        .react-calendar__month-view__weekdays__weekday {
          @extend .normal-12-500;
          color: colors.$gray-7;
          text-align: center;

          abbr {
            text-decoration: none !important;
            cursor: auto;
          }
        }
      }

      // Day buttons
      .react-calendar__month-view__days {
        .react-calendar__tile {
          @extend .normal-12;
          color: colors.$gray-2;
          padding: 0;
          height: 28px;
          background-color: transparent;
          border: 3px solid white;
          border-radius: 50%;

          &:disabled {
            color: colors.$gray-7;
          }

          // current day
          &.react-calendar__tile--now {
            @extend .normal-12-500;
            font-size: 11px;
            background-color: colors.$gray-3;
            color: var(--primary-blue);
            line-height: 24px;
          }

          // day selected
          &.react-calendar__tile--active {
            @extend .normal-12-500;
            font-size: 11px;
            background-color: var(--primary-blue);
            color: white;
            line-height: 24px;
          }

          // day from neighboring month
          &.react-calendar__month-view__days__day--neighboringMonth {
            color: colors.$gray-1;
          }
        }
      }
    }

    .react-calendar__year-view,
    .react-calendar__decade-view,
    .react-calendar__century-view {
      // Month, year or decade buttons
      .react-calendar__year-view__months,
      .react-calendar__decade-view__years,
      .react-calendar__century-view__decades {
        .react-calendar__tile {
          @extend .normal-12;
          color: colors.$gray-2;
          padding: 0;
          height: 35px;
          width: auto;
          background-color: white;
          border: none;
          border-radius: 5px;
          text-align: center;

          &:disabled {
            color: colors.$gray-7;
          }

          &.react-calendar__tile--now {
            background-color: colors.$gray-3;
            color: var(--primary-blue);
          }

          &.react-calendar__tile--hasActive {
            background-color: var(--primary-blue);
            color: white;
          }
        }
      }

      .react-calendar__century-view__decades {
        .react-calendar__tile {
          font-size: 9px;
        }
      }

      .react-calendar__year-view__months {
        .react-calendar__tile {
          font-size: 11px;
          text-transform: capitalize;
        }
      }
    }
  }
}
