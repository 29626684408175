@use '../../styles/colors' as colors;
@use '../../styles/app.scss';

.audio-player-wrapper {
  width: 400px;
  min-width: 250px;

  .audio-info {
    @extend .normal-12-500;
    color: colors.$gray-1;
    padding-left: 25px;
    padding-top: 5px;
  }

  .rhap_container {
    width: 100% !important;
    border-radius: 40px;
    box-shadow: 0 2px 5px 5px rgba(242, 242, 242, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.12),
      0 4px 8px 0 rgba(183, 183, 183, 0.14);

    .rhap_controls-section {
      flex: 1;

      .rhap_play-pause-button {
        svg {
          fill: var(--primary-blue) !important;

          path {
            fill: var(--primary-blue) !important;
          }
        }
      }
    }

    .rhap_progress-section {
      flex: 99;

      .rhap_time {
        @extend .normal-14-500;
      }

      #rhap_current-time,
      .rhap_current-time {
        order: 1;
      }

      .rhap_total-time {
        display: none;
      }

      .rhap_progress-filled {
        background-color: var(--primary-blue) !important;
      }

      .rhap_progress-indicator {
        background-color: colors.$blue-3 !important;
      }
    }

    .rhap_additional-controls,
    .rhap_volume-controls {
      display: none;
    }
  }
}
