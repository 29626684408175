@use "../../styles/colors" as colors;


.styled-switch {
    .ant-switch {
        height: 24px !important;
        width: 40px !important;
        min-width: 30px;
    }

    .ant-switch-checked {
        background-color: var(--primary-blue) !important;
        height: 24px !important;
        width: 40px !important;
    }

    

    .ant-switch-handle {
        height: 24px !important;
        width: 24px !important;
        top: 0px !important;
        border-radius: 100% !important;
        left: -5px !important;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
    }

    .ant-switch-handle::before {
        height: 24px !important;
        width: 24px !important;
        top: 0px !important;
        border-radius: 100% !important;
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 18px - 2px) !important;

        margin-left: 2px;
    }

    .switch-label {
        padding: 0;
        padding-left: 10px;
    }

    &.placement-label-left { 
        display: flex; 
        flex-direction: row-reverse;
        justify-content: flex-end;

        .switch-label {
            padding: 0;
            padding-right: 20px;
        }
    }
}