@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.card-notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    position: relative;
    background-color: colors.$white-2;
    margin-top: 12px;
    box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2),
        0 4px 8px 0 rgba(228, 228, 228, 0.2);
    cursor: pointer;


    .card-body {
        line-height: 25px;
        padding: 20px;
        border-radius: 8px 0 0 8px;
        width: 100%;

        .content-notif {
            padding: 0 !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-bottom: 10px;
        }
    }

    .farol-blue {
        top: 50%;
        left: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        transform: translateY(-50%);
        background-color: var(--primary-blue);
    }

    .card-actions {
        background-color: colors.$gray-1;
        height: 100%;
        padding: 8px;
        border-radius: 0 8px 8px 0;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .delete-button {
        @extend .icon;
    }

    .join {
        display: flex;
        align-items: center;
    }
}
