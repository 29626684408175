@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.select-proposal-type {
    // min-height: 527px;
    // padding: 24px;
    border-radius: 8px;
    // margin-bottom: 24px;
    // background-color: colors.$white-2;
    // box-shadow: 0 2px 5px 5px rgba(242, 242, 242, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.12),
    //   0 4px 8px 0 rgba(183, 183, 183, 0.14);

    .input-title {
        // margin-bottom: 25px;
    }

    .card-select-type {
        padding-top: 15px;

        svg {
            width: 22px;
            height: auto;
        }

        .ant-divider-horizontal {
            margin: 0 !important;
            border-color: colors.$gray-5;
        }

        &:last-child .ant-divider-horizontal {
            border-color: transparent;
        }

        > .ant-col:first-child {
            min-width: 45px;
            text-align: center;
        }

        &:hover {
            background-color: colors.$gray-3;
            cursor: pointer;
        }
    }
}