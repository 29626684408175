@use "../../common/styles/colors"as colors;
@use "../../common/styles/app.scss";


.login-container {
    display: flex;
    flex-direction: column;

    .left-side {
        display: none;
        min-height: 100vh;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180.76deg, #eaeef9 11.55%, rgba(212, 241, 246, 0) 104.03%);
    }

    .right-side {
        padding: 0;
        width: 100%;

        .logo-container {
            height: 60px;
            display: flex;
            padding: 0 16px;
            align-items: center;
            background-color: #FFF;

            img {
                width: 120px;
            }
        }

        .subtitle {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 27px;
            text-align: center;
            margin: 30px 0 16px;
            font-family: Poppins;
            color: colors.$black-0;
        }

        .forgot-password {
            font-size: 12px;
            cursor: pointer;
            margin-top: 12px;
            letter-spacing: 0;
            line-height: 21px;
            margin-bottom: 20px;
            font-family: Poppins;
            color: colors.$black-0;
        }

        .error-message {
            text-align: center;
        }

        hr {
            width: 90%;
            opacity: 0.4;
            line-height: 1px;
            margin-bottom: 16px;
            color: colors.$gray-0;
        }

        .ant-form {
            margin: 0 auto;
            padding: 0 16px;
            max-width: 450px;

            .ant-form-item {
                .ant-form-item-label {
                    padding: 0 0 4px !important;

                    label {
                        font-size: 12px;
                    }
                }

                .rounded-input {
                    width: 100% !important;
                    height: 40px !important;
                }

                .input-container .icon-button {
                    margin-top: 10px !important;
                }
            }
        }

        .blue-button,
        .apple-login .blue-button,
        .google-login button {
            width: 100% !important;
            height: 40px !important;
            margin: 0 0 15px !important;
        }

        .provider-login {
            button {
                display: flex;
                font-weight: 500;
                align-items: center;
                margin-bottom: 24px;
                justify-content: center;
                height: 40px !important;
                font-size: 14px !important;
                border-radius: 28px !important;

                span {
                    font-weight: 500;
                    font-size: 14px !important;
                }
            }

            div {
                display: flex;
                align-items: center;
            }
        }

        .apple-login {
            @extend .provider-login;

            button {
                background-color: black;
            }
        }

        .google-login {
            @extend .provider-login;

            button {
                background-color: rgb(255, 255, 255);
                box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
                font-family: Roboto, sans-serif;
                span {
                    color: colors.$gray-0;
                }
            }
        }

        .text-create-account {
            padding: 0 16px;
            text-align: center;

            span {
                font-size: 12px !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .login-container {

        .right-side {
            .logo-container {
                justify-content: center;
            }

            .ant-form {
                width: 50%;
                margin: 0 auto;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .login-container {
        min-height: 100vh;

        .left-side {
            left: 0;
            width: 50%;
            display: flex;
            position: fixed;
            min-height: 100vh;
            align-items: center;
            justify-content: center;
            background: linear-gradient(180.76deg, #eaeef9 11.55%, rgba(212, 241, 246, 0) 104.03%);
        }

        .right-side {
            width: 50%;
            display: flex;
            padding: 50px 0;
            text-align: center;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            transform: translateX(50%);

            .logo-container {
                background-color: transparent;
                img {
                    width: 220px;
                }
            }

            .subtitle {
                color: colors.$black-0;
                font-family: Poppins;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 27px;
                margin: 20px 0;
                text-align: center;
            }

            .forgot-password {
                font-size: 14px;
                cursor: pointer;
                letter-spacing: 0;
                line-height: 21px;
                margin-bottom: 16px;
                font-family: Poppins;
                color: colors.$black-0;
                margin-top: 0 !important;
            }

            .error-message {
                text-align: center;
            }

            hr {
                opacity: 0.4;
                width: 312px;
                margin: 24px 0;
                line-height: 1px;
                color: colors.$gray-0;
            }

            .ant-form {
                width: 100%;

                .ant-form-item {
                    padding-bottom: 0 !important;
                    .ant-form-item-label {
                        padding: 0 0 6px !important;
                    }

                    .rounded-input {
                        width: 100% !important;
                        height: 50px !important;
                    }

                    .input-container .icon-button {
                        margin-top: 15px !important;
                        margin-left: -34px !important;
                    }
                }
            }

            .blue-button,
            .apple-login .blue-button,
            .google-login button {
                width: 100% !important;
                height: 50px !important;
                margin: 0 0 8px !important;
            }

            .provider-login {
                button {
                    border-radius: 28px !important;
                    width: 314px !important;
                    height: 50px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 24px;
                    font-weight: 500;
                    font-size: 14px !important;

                    span {
                        font-weight: 500;
                        font-size: 14px !important;
                    }
                }

                div {
                    display: flex;
                    align-items: center;
                }
            }

            .apple-login {
                @extend .provider-login;

                button {
                    background-color: black;
                }
            }

            .google-login {
                @extend .provider-login;

                button {
                    span {
                        color: colors.$gray-0;
                    }
                }
            }
        }
    }
}