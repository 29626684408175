@use "../../../../common/styles/colors";

.feedback-container {
    display: flex;
    font-size: 12px;
    padding: 20px 0;
    align-items: center;
    flex-direction: column;
    letter-spacing: 0.44px;

    &.pending {
        color: colors.$yellow-0;
        fill: colors.$yellow-0;
    }
    &.success {
        color: colors.$green-2;
        fill: colors.$green-2;
    }
    &.failed, &.canceled {
        color: colors.$red-3;
        fill: colors.$red-3;
    }

    .title {
        font-size: 16px;
        font-weight: 700;
        margin-top: 12px;
    }

    svg {
        width: 205px;
        height: 50px;
    }

    .text {
        font-size: 14px;
        font-weight: 500;
        max-width: 280px;
        text-align: center;
    }

}

@media screen and (min-width: 768px) {
    .feedback-container {
        font-size: 14px;
        padding: 40px 0;

        .title {
            font-size: 18px;
        }
    
        svg {
            width: 215px;
            height: 70px;
        }
    
        .text {
            font-size: 14px;
            max-width: 340px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .feedback-container {
        font-size: 18px;
        text-align: center;
        align-items: center;
        justify-content: center;

        svg {
            width: 215px;
            height: 86px;
        }
        
        .title {
            font-size: 32px;
            font-weight: 700;
            margin: 24px 0 16px;
        }
    
        .text {
            font-size: 16px;
            max-width: 416px;
            font-weight: 400;
        }
    }
}