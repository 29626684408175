@use "../../common/styles/colors"as colors;
@use "../../common/styles/app.scss";

$mobile-screen: 768px;
$box-padding-right: 24px;
$content-width: 480px;
$content-width-porcent: 60%;

.aside-plans {
    display: none;
    padding-bottom: 15px;

    &.open {
        top: 0;
        right: 0;
        width: 100%;
        display: block;
        position: fixed;
        z-index: 9999995;
        min-height: 100vh;
        padding: 20px 28px;
        background-color: rgba(0, 0, 0, 0.6);

        @media (max-width: $mobile-screen) {
            .content-chat-proposal {
                left: 0;
                width: 100vw !important;
                max-width: 100vw !important;

                .top-bar-chat, .bottom-bar-chat {
                    left: 0;
                    width: 100vw !important;
                    max-width: 100vw !important;
                }
                
                .ant-row.box-chat-actions {
                    .ant-col.col-chat-menu {
                        .background-menu {
                            width: 100vw;
                            left: 0;
                        }
                    }
                }
            }
        }

        .content-chat-proposal {
            top: 0;
            right: 0;
            width: $content-width-porcent;
            height: 100%;
            overflow-y: auto;
            max-width: $content-width;
            min-height: 100vh;
            position: absolute;
            background-color: #F1F5FF;
            animation-name: open;
            animation-duration: 0.9s;

            @keyframes open {
                from {
                    width: 0;
                    opacity: 0;
                }

                to {
                    opacity: 1;
                    width: $content-width-porcent;
                }
            }

            .top-bar-chat, .bottom-bar-chat {
                position: fixed;
                z-index: 9999995;
                right: 0;
                width: $content-width-porcent;
                max-width: $content-width;
                animation-name: open;
                animation-duration: 0.9s;
            }

            .top-bar-chat {
                top: 0;
                
            }

            .bottom-bar-chat {
                bottom: 0;
            }

            .center-bar-chat {
                // width: 100%;
                padding: 10px;
                padding-right: 25px;
                overflow-x: none;
                z-index: 1;
                overflow-x: hidden;
                overflow-y: auto;
            }

            .ant-row.box-top-bar-search {
                background-color: #001B66;
                padding:  8px $box-padding-right !important;
                height: 42px;

                .ant-col:last-child {
                    text-align: right !important;
                }


                .search-icon, .arrow-right-icon {
                    &:hover {
                        opacity: 0.6;
                    }
                }

                .arrow-right-icon {
                    display: none;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    position: relative;
                    // top: -8px;
                    // left: -5px;

                    svg {
                        width: 29px;
                        height: 29px;
                        color: white;
                        path {
                            fill: white !important;
                            stroke: white !important;

                        }
                    }
                }

                .close-icon {
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    position: relative;
                    // top: 6px;
                    left: -60px;
                    background-color: white;
                    border-radius: 4px;
                    width: 20px;
                    height: 20px;

                    svg {
                        margin-top: 3px;
                        margin-left: 1px;
                        width: 13px;
                        height: 13px;

                        path {
                            fill: #141414;
                        }
                    }
                }

                @media (max-width: $mobile-screen) {
                    .arrow-right-icon {
                        display: inline !important;
                    }

                    .close-icon {
                        display: none;
                    }
                }
                
                .search-bar-animation {
                    // display: inline-block;
                    height: 26px;
                    padding: 0 !important;
                    
                    &.search-close {
                        .search-input {
                            display: none;
                        }
                
                        .search-icon {
                            right: -10px;
                            fill: white;
                            
                            svg {
                                height: 20px;
                                font-size: 20px;
                
                                path {
                                    fill: white;
                                }
                            }
                        }
                    }
                
                    .search-icon {
                        right: 5px;
                        
                        svg {
                            height: 20px;
                            font-size: 20px;
                        }
                    }
                }
            }            
        }

        .ant-row.box-person-chatting {
            background-color: #002999;
            padding:  12px $box-padding-right !important;
            color: white;

            .ant-col.person-info {
                max-width: 50%;

                > .ant-row .ant-col:last-child {
                    max-width: 70%;

                    @media (max-width: $mobile-screen) {
                        max-width: 60%;
                    }

                    div {
                        width: 100%;
                        text-align: left;                
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
               }
            }

            .ant-col.proposal-info {
                max-width: 50%;
                
                div {
                    width: 100%;
                    text-align: right;                
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                div:first-child {
                    a {
                        text-decoration: underline;
                        color: white !important;
                    }
                }
            }
        }

        .ant-row.box-proposal-target {
            background-color: white;
            padding:  12px $box-padding-right !important;
            color:  colors.$black-0;
            border-bottom: 1px solid #E0E0E0;

            .label-status-proposal {
                &[data-status="draft"] {
                color: #555555;
                // background: rgba(85, 85, 85, 0.1);
                // border-color: #555555;
                }
            
                &[data-status="refused"] {
                color: #202020;
                // background: rgba(85, 85, 85, 0.1);
                // border-color: #555555;
                }
            
                &[data-status="waiting_review"],
                &[data-status="waiting_accept"] {
                color: #cf9500;
                // background: rgba(247, 178, 5, 0.1);
                // border-color: #f7b205;
                }
            
                &[data-status="accepted"] {
                color: #1d945a;
                // background: rgba(29, 148, 90, 0.1);
                // border-color: #1d945a;
                }
            
                &[data-status="expired"] {
                color: #8c0100;
                // background: rgba(140, 1, 0, 0.1);
                // border-color: #8c0100;
                }
            
                &[data-status="canceled"] {
                color: #e90000;
                // background: rgba(233, 0, 0, 0.1);
                // border-color: #e90000;
                }
            
                &[data-status="waiting_sign_contract"] {
                color: #3300ac;
                // background: rgba(51, 0, 172, 0.1);
                // border-color: #3300ac;
                }
            
                &[data-status="waiting_nfs"] {
                color: #0038d1;
                // background: rgba(0, 56, 209, 0.1);
                // border-color: #0038d1;
                }
            
                &[data-status="finalized"] {
                color: #074929;
                // background: rgba(7, 73, 41, 0.1);
                // border-color: #074929;
                }
            }
        }

        .ant-row.box-chat-actions {
            padding: 12px 10px !important;

            .white-button {
                height: 25px !important;
                // width: 80px !important;
                padding: 0 10px !important;
                font-size: 14px;
            }

            .more-button {
                height: 24px;
                width: 24px;
                border-radius: 100%;
                cursor: pointer;
            
                .more-icon {
                  // height: 24px;
                  // width: 24px;
            
                    svg {
                        position: relative;
                        transform: rotate(90deg);
                    }
                }
            }
            
            .more-button:hover {
                background-color: colors.$gray-4;
            }
            
            .ant-col.col-chat-menu {
                display: flex;
                justify-content: flex-end;

                .background-menu {
                    background-color: black;
                    position: fixed;
                    top: 0;
                    right: 0;
                    width: 100vh;
                    height: 100vh;
                    opacity: 0.1;
                    z-index: 9999998;
                    width: $content-width;
                }
            
                .chat-proposal-menu {
                    // padding: 5px 0;
                    max-width: 315px;
                    border-radius: 8px !important;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 1px rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14) !important;
                    z-index: 9999999;

                    .ant-menu-title-content {
                        z-index: 9999999;
                        @extend .normal-16;
                        height: 40px;
                        display: flex;
                        line-height: 35px;
                        align-items: center;

                        .icon-menu {
                            margin-right: 12px;

                            .cancel-icon {
                                position: relative;
                                top: 3px;

                                svg {
                                    color: colors.$gray-0;
                                    width: 18px;
                                    height: 18px;
                                }
                            }

                            .navigation-flag-icon {
                                position: relative;
                                top: 3px;
                                left: -5px;

                                svg {
                                    stroke: colors.$gray-0;
                                    width: 22px;
                                    height: 22px;
                                }
                            }
                        }
                    }
                
                    .ant-menu-item {
                        z-index: 9999999;
                        padding-bottom: 0;
                        padding-top: 0;
                        display: flex;
                        align-items: center;
                        background-color: white;
                        margin: 0;
                        // border-radius: 8px 8px 0 0;
                        border-radius: 8px;

                        .ant-menu-item:first-child {
                            border-radius: 8px 8px 0 0;
                        }
                    }
                    

                    .ant-menu-item:hover {
                        background-color: colors.$gray-6;
                    }

                    .ant-menu-item-active {
                        background-color: white;
                    }

                    // .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu-item:hover, 
                    // .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, 
                    // .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
                    //     color: none;
                    // }

                    // .ant-menu-item:after {
                    //     border: none !important;
                    //     --antd-wave-shadow-color: none !important
                    // }
                
                    .ant-menu-item-disabled {
                        opacity: 0.5;
                    }
                
                    .ant-menu-item-divider {
                        margin: 0;
                    }

                    .ant-menu-item-divider:first-child {
                        display: none;
                    }
                }
            }
        }

        .ant-row.box-chat-inputs {
            background-color: white;
            border-top: 1px solid #E0E0E0;
            padding: 12px $box-padding-right;

            >:first-child {
                padding-bottom: 8px;
            }

            textarea.ant-input {
                height: 40px;
                border-radius: 8px;
                resize: none;
                overflow: hidden;
                min-height: 40px !important;
                max-height: 80px;
            }

            .btn-send-message {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                background-color: #002999;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    opacity: 0.8;
                    background-color: #002999;
                }

                .send-message-icon {
                    position: relative;
                    top: 8px;
                    left: 9px;

                    svg {
                        width: 22px;
                        height: 22px;
                        stroke: white;
                    }
                }
            }
        }

        .chat-message {
            text-align: center;
            width: 100%;
            margin: 16px;
            font-family: Poppins;
            font-weight: 400;
            font-size: 14px;
            color: #141414;

            .status-msg-icon {
                display: none;
            }

            &[data-type="message"] {
                text-align: left;
                margin: 8px;

                > span {
                    display: inline-block;
                    background-color: white;
                    max-width: 90%;
                    padding: 5px 10px;
                    border-radius: 8px;
                    color: black;
                    border: 1px solid #E0E0E0;
                }
                

                &[data-fromuser="true"] {
                    text-align: right !important;

                    > span {
                        background-color: #D9E1F8;
                        border: 1px solid #8CA5EA;
                    }

                    .status-msg-icon {
                        display: inline;
                        position: relative;
                        left: 4px;
                        top: 0px;
                        color: #8CA5EA;
        
                        svg {
                            width: 8px;
                            height: 8px;
                        }
                    }

                    
                    .triangle {
                        $size-triangle: 18px;
                        $rotate-triangle: 15deg;
                        $color-background: #D9E1F8;
                        $color-border: #8CA5EA;
                        
                        display: inline-block;
                        height: 0;
                        width: 0;
                        transform: rotate(-$rotate-triangle);
                        position: relative;
                        bottom: -5px;
                        left: -10px;
                        
                        > span {
                            display: block;
                            width: 0;
                            height: 0;
                            border-left: calc($size-triangle / 2) solid transparent;
                            border-bottom: 0 solid transparent;
                            border-right: 0 solid transparent;
                            position: relative;
                        }

                        > span:first-child,
                        > span:nth-child(2) {
                            border-top: $size-triangle solid $color-border;
                            z-index: 9999996;
                        }

                        > span:first-child {
                            left: -1px;
                        }

                        > span:nth-child(2) {
                            left: 1px;
                            top: calc($size-triangle * -1);
                        }

                        > span:nth-child(3) {
                            border-top: $size-triangle solid $color-background;
                            z-index: 9999997;
                            top: calc($size-triangle * -2);
                        }

                        > span:nth-child(4) {
                            width: $size-triangle;
                            height: calc($size-triangle / 2);
                            z-index: 9999998;
                            top: calc($size-triangle * -3);
                            left: calc($size-triangle / 4 * -1);
                            background-color: $color-background;
                            // background: linear-gradient(to top, transparent 0%, $color-background 10%);
                            // background: -webkit-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            // background:    -moz-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            // background:     -ms-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            // background:      -o-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            transform: rotate($rotate-triangle);
                        }
                    }
                }

                &[data-fromuser="false"] {
                    
                    .triangle {
                        $size-triangle: 18px;
                        $rotate-triangle: 180deg;
                        $color-background: white;
                        $color-border: #E0E0E0;

                        display: inline-block;
                        height: 0;
                        width: 0;
                        transform: rotate(-$rotate-triangle);
                        position: relative;
                        top: calc($size-triangle * -1 + 7px);
                        left: 13px;
                        
                        > span {
                            display: block;
                            width: 0;
                            height: 0;
                            border-left: calc($size-triangle / 2) solid transparent;
                            border-bottom: 0 solid transparent;
                            border-right: calc($size-triangle / 2) solid transparent;
                            position: relative;
                        }

                        > span:first-child,
                        > span:nth-child(2) {
                            border-top: $size-triangle solid $color-border;
                            z-index: 9999996;
                        }

                        > span:first-child {
                            left: -1px;
                        }

                        > span:nth-child(2) {
                            left: 1px;
                            top: calc($size-triangle * -1);
                        }

                        > span:nth-child(3) {
                            border-top: $size-triangle solid $color-background;
                            z-index: 9999997;
                            top: calc($size-triangle * -2);
                        }

                        > span:nth-child(4) {
                            width: $size-triangle;
                            height: calc($size-triangle / 2);
                            z-index: 9999998;
                            top: calc($size-triangle * -3);
                            left: 0px;
                            background-color: $color-background;
                            // background: linear-gradient(to top, transparent 0%, $color-background 10%);
                            // background: -webkit-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            // background:    -moz-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            // background:     -ms-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            // background:      -o-linear-gradient(to top, transparent 0%, $color-background 10%); 
                            transform: rotate($rotate-triangle);
                        }
                    }
                }                
            }

            &[data-type="date"] {
                font-weight: 500;
                opacity: 0.8;
            }

            &[data-type="alert"] {
                display: flex;
                justify-content: center;
                margin: 16px 8px;
                
                .lamp-icon {
                    position: relative;
                    top: 3px;
                    margin-right: 5px;
                    
                    svg {
                        
                        width: 18px;
                        height: 18px;
                    }
                }
                
                > span {
                    display: inline-block;
                    max-width: 90% !important;
                    border-radius: 8px;
                    border: 1px solid #E0E0E0;
                    background-color: #E0E0E0;
                    padding: 5px 16px;
                    background-color: white;

                    .text {
                        // margin-left: 10px;
                    }
                }
            }
        }
    }
}
