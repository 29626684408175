@use "../../styles/colors"as colors;
@use '../../styles/app.scss';

.top-blank-box.invoicing-progress {
    width: 100%;
    height: 145px;
    padding: 12px 27px 12px 27px !important;
    border: 1px solid var(--color-neutral-light, #E0E0E0);
    background-color: colors.$white-2;

    .top-box-title {
        @extend .font-14;
        line-height: normal;
        color: colors.$blue-4;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        

        .money-icon {
            padding-right: 8px;
            top: 2px;
            position: relative;
        }
    }

    .top-box-content {
        @extend .normal-36;
        font-size: 30px;
        line-height: normal;
        padding-top: 15px;
    }
}





.invoicing-progress {
    .title > div:last-child {
        font-size: 10px;
        color: colors.$gray-2;
    }

    .circle-info-icon {
        cursor: pointer;
    }

    .invoicing-progress-bar {
        width: 100%;
        text-align: center;
        padding: 25px 0;
        display: flex;
        justify-content: center;
        --bar-width: 100%;
        --bar-height: 13px;
        // $progress: calc(50% - 30px); // will be changed by useEffect
        $score-value-height: 20px;
        $score-point-height-out: 1px;
        $move-top-elements: calc(($score-value-height * -1) - ($score-point-height-out / 2));


        .progress-bar {
            background-image: linear-gradient(90deg, #34b048, #FF9700, #fd4f36);
            width: var(--bar-width);
            height: var(--bar-height);
            border-radius: 15px;
            position: relative;
            z-index: 10;

            .progress-bar-point {
                width: 60px;
                text-align: center;
                // margin-left: $progress;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                position: relative;
                top: $move-top-elements;
            }

            .bar-value {
                font-size: 12px;
                font-weight: 600;
                height: $score-value-height;
                color: colors.$black-0;
            }

            .bar-point {
                width: 8px;

                img {
                    position: relative;
                    left: -8px;
                }
            }

            .bar-scale {
                font-size: 11px;
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: space-between;
                position: relative;
                top: -18px;
            }
        }
    }
}