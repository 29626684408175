@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.icon-button {
    position:absolute;
    margin-left: -30px;
    margin-top: 5px;
    cursor: pointer;
    color: colors.$gray-2;
    background-color: white;

    svg {
        width: 20px;
        height: auto;
        color: colors.$gray-7;
    }
}