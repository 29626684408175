.modal-description {
  margin-bottom: 8px;

  div:first-child {
    margin-bottom: 3px;
  }

  div:last-child {
    padding-right: 30px;
  }
}

.bank-selected-box {
  width: 100%;
  padding: 15px 0;
  text-align: left;
}

.favorite-item {
  padding: 10px 0;

  .favorite-icon {
    position: relative;
    top: 3px;
  }
}
