@use "../../../../common/styles/colors";

.plan-description {
    font-size: 11px;
    padding: 24px 0;
    margin: 16px 0 24px;
    border-top: 1px solid rgba(0, 0, 0, .15);
    border-bottom: 1px solid rgba(0, 0, 0, .15);

    .form-title {
        display: flex;
        margin-bottom: 0;
        padding-right: 10px;
        justify-content: space-between;

        .arrow {
            position: relative;

            &::before,
            &::after {
                content: "";
                top: 15px;
                width: 8px;
                height: 1px;
                position: absolute;
                display: inline-block;
                background-color: #000;
                transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                -ms-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
            }

            &::before {
                left: 0;
                transform: rotate(-45deg);
            }

            &::after {
                right: -3px;
                transform: rotate(45deg);
            }

            &.open {
                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .request-product {
        display: flex;
        max-height: 0;
        overflow: hidden;
        flex-direction: column;
        transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        -ms-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;

        &.open {
            max-height: 300px;
            transition: all 0.2s ease-in;
            -o-transition: all 0.2s ease-in;
            -ms-transition: all 0.2s ease-in;
            -moz-transition: all 0.2s ease-in;
            -webkit-transition: all 0.2s ease-in;
        }

        .plan-card {
            display: flex;
            padding: 20px 14px;
            border-radius: 8px;
            margin: 18px 0 12px;
            flex-direction: column;
            align-items: flex-start;
            background-color: colors.$white-1;

            &.coupon {
                margin: 0;
                padding: 8px 14px;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
            }

            .plan-name {
                font-size: 16px;
                font-weight: 500;
            }

            .plan-name,
            .plan-text,
            .plan-type {
                margin-bottom: 6px;
            }

            .upd-plan-value {
                width: 100%;
                padding-top: 10px;
                margin: 0 0 -10px;
                border-top: 1px solid rgba(0, 0, 0, .15);

                .plan-type {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &.coupon-value b {
                        color: colors.$green-1;
                    }
                }
            }

            .plan-value {
                width: 100%;
                display: flex;
                margin: 10px 0 0;
                padding-top: 10px;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid rgba(0, 0, 0, .15);

                .price {
                    font-size: 15px;
                    font-weight: 500;
                }
            }


        }
    }
}

@media screen and (min-width: 768px) {
    .plan-description {
        font-size: 12px;
        padding: 32px 0;
        margin: 20px 0 28px;

        .form-title {
            padding-right: 11px;

            .arrow {

                &::before,
                &::after {
                    content: "";
                    top: 22px;
                    width: 12px;
                    height: 2px;
                }


                &::after {
                    right: -4px;
                }

                &.open {
                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        .request-product {
            .plan-card {
                padding: 30px 24px;
                margin: 20px 0 14px;

                .plan-name,
                .plan-text,
                .plan-type {
                    margin-bottom: 10px;
                }

                .plan-value {
                    width: 100%;
                    margin: 10px 0 0;
                    padding-top: 20px;

                    .price {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .plan-description {
        margin: 0;
        padding: 0;
        border: none;
        font-size: 14px;

        .form-title {
            padding-right: 11px;

            .arrow {
                display: none;
            }
        }

        .request-product {
            max-height: none !important;

            .plan-card {
                padding: 24px;
                margin: 24px 0 18px;

                .plan-name,
                .plan-text,
                .plan-type {
                    margin-bottom: 8px;
                }

                .plan-value {
                    width: 100%;
                    font-size: 16px;
                    margin: 23px 0 0;
                    padding-top: 29px;

                    .price {
                        font-size: 24px;
                    }
                }
            }
        }

        .form-observation {
            font-size: 14px;
            line-height: 22px;
        }
    }
}