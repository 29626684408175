@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.share-proposal-step {
    min-height: 527px;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    background-color: colors.$white-2;
    border: 1px #D9D9D9 solid;
    

    .ant-row.share-title {
        @extend .normal-16-500;
        // padding: 20px 0;
    }

    .ant-row.share-subtitle {
        @extend .normal-12;
        padding: 0 0 20px 0;
    }


    .blue-button {
        min-width: 280px !important;
    }

    .ant-row.share-options {
        width: 100%;
        margin: 0 !important;

        .ant-col {
            width: 100%;
            display: flex;
            justify-content: center;

            .white-button {
                min-width: 280px !important;
                font-size: 12px;
                background-color: white;
                color: var(--primary-blue) !important;
                border: 1px solid var(--primary-blue) !important;

                .share-btn-icon {
                    &.whatsapp-icon svg {
                        width: 22px;
                        height: 22px;
                        position: relative;
                        top: 3px;
    
                        path {
                            fill: var(--primary-blue) !important;
                        }
                    }
    
                    &.mail-icon svg {
                        width: 22px;
                        height: 22px;
                        position: relative;
                        top: 3px;
                        
    
                        path {
                            stroke: var(--primary-blue) !important;
                        }
                    }
    
                    &.link-icon svg {
                        width: 22px;
                        height: 22px;
                        position: relative;
                        top: 3px;
                        
                        path {
                            stroke: var(--primary-blue);
                        }
                    }
                }
            }

            .white-button:disabled {
                background-color: colors.$gray-4 !important;
                border: 1px solid colors.$gray-7 !important;
                color: colors.$gray-2 !important;

                .share-btn-icon {
                    &.whatsapp-icon svg {
                        path {
                            fill: colors.$gray-7 !important;
                        }
                    }
    
                    &.mail-icon svg {
                        path {
                            stroke: colors.$gray-7 !important;
                        }
                    }
    
                    &.link-icon svg {
                        path {
                            stroke: colors.$gray-7;
                        }
                    }
                }
            }


            
        }
    }
}