@use "../../../../../../common/styles/colors" as colors;
@use "../../../../../../common/styles/app.scss";

.edit-social-medias {
  .ant-form {
    padding-top: 10px;
    max-height: 50vh;
    overflow: auto;
    overflow-x: hidden;
  }

  .ant-row {
    margin-bottom: 10px;
    padding-right: 20px;
    width: 100%;
  }

  .ant-radio-group {
    width: 100%;
  }

  .plus-icon {
    position: relative;
    top: 5px;
    padding: 0 50%;

    svg {
      width: 20px;
      height: auto;
      color: colors.$gray-2;
    }
  }

  .delete-icon {
    position: relative;
    top: 25px;
    padding: 0 50%;

    svg {
      width: 25px;
      height: auto;

      path {
        fill: colors.$gray-2 !important;
      }
    }
  }

  .social-media {
    .favorite-icon {
      margin-left: 5px;
      position: relative;
      top: 3px;
    }
  }

  .favorite-item {
    padding: 0;

    .favorite-icon {
      position: relative;
      top: 5px;
    }
  }
}
