@use "../../../../common/styles/colors" as colors;

.root-content {
  width: 100%;
  display: none;

  > * {
    padding-top: 25px;
  }

  &.visible {
    display: block;
  }
}

.header-row {
  width: 100%;
  padding: 16px !important;
  margin: 0 !important;
}

.body-row {
  width: 100%;
  padding: 16px !important;
  background-color: white;
  border-radius: 8px;
  margin: 12px 0 0 0 !important;
  box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2),
    0 4px 8px 0 rgba(228, 228, 228, 0.2);
}

.edit-button,
.show-button,
.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 24px;
    height: auto;

    path {
      fill: colors.$gray-2 !important;
    }
  }
}

.delete-button {
  position: relative;
  top: 0px;
}
