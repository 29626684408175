@use "../../styles/colors" as colors;

.slick-slider {
    padding: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    align-items: center;

    .slick-list {
        width: 99%;

        .slick-slide {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;

            .item {
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;

                img {
                    width: none;
                    margin: auto;
                    max-width: 350px;
                }

                p.title {
                    font-size: 32px;
                    margin: 40px auto 0;
                    line-height: 28.5px;
                    max-width: 383px;
                    font-weight: 600;
                    font-size: 25px;
                }

                p.text {
                    font-size: 20px;
                    margin: 40px auto 0;
                    max-width: 370px;
                    font-weight: 500;
                    line-height: 24.5px;
                }
            }
        }
    }

    .slick-dots {
        margin-top: 60px;
        bottom: 0px;
        display: flex;
        position: relative;
        justify-content: center;

        li {
            margin: 0 4px;

            button {
                &::before {
                    top: 50%;
                    left: 50%;
                    width: 7px;
                    height: 7px;
                    border: none;
                    overflow: hidden;
                    border-radius: 4px;
                    color: colors.$gray-0;
                    background: colors.$gray-0;
                    transform: translate(-50%, -50%);
                    transition: all 0.3s ease-in-out;
                }
            }

            &.slick-active {
                button::before {
                    width: 18px;
                    background: var(--primary-blue);
                    color: var(--primary-blue);
                }
            }
        }
    }
}
