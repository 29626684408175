@use '../../styles/colors' as colors;

.input-container {
    .icon-button {
        position:absolute;
        margin-left: -30px;
        margin-top: 15px;
        cursor: pointer;
        color: colors.$gray-2;
        background-color: white;

        svg {
            width: 20px;
            height: auto;
        }
    }
}