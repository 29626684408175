@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";

.body-container-contract {
  .top-bar .topbar-root {
    width: 100%;

    .topbar-container {
      width: 100%;

      img {
        width: 220px;
      }
    }
  }

  .content {
    .proposal-contract {
      width: 100%;

      .pdf-view {
        display: flex;
        padding-top: 40px;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        canvas {
          width: 80%;
          max-width: 1090px;
        }

        .main-content-top {
          width: 80%;
          height: 12px;
          max-width: 1090px;
          display: inline-block;
          background-color: var(--primary-blue);
          border-radius: 8px 8px 0 0;
        }
      }
    }

    .plain-button {
      span {
        font-size: 14px;
      }
    }

    .actions-content {
      padding: 0 16px !important;

      .blue-button {
        width: 80%;
        min-width: 280px;
        height: 48px !important;
        padding: 0 16px !important;

        span {
          display: flex;
          font-size: 14px;
        }
      }
    }
  }

  .rouded-box {
    padding: 0 !important;
    background-color: #fff !important;
    height: calc(100vh - 200px);

    #contract-iframe {
      border: none;
      width: 100%;
      height: calc(100vh - 315px);
      overflow: auto;
    }

    #contract-iframe[data-signed="true"] {
      height: 100%;
      border-radius: 0 0 8px 8px;
    }

    .actions-content {
      padding: 32px !important;
    }

    .blue-button {
      min-width: 180px;
    }
  }
}

@media screen and (max-width: 768px) {
  .body-container-contract {
    .top-bar {
      height: 85px !important;

      .topbar-root .topbar-container {
        img {
          width: 180px;
        }
      }
    }

    .content {
      padding: 0 !important;

      .proposal-contract {
        width: 100% !important;
        .pdf-view {
          .main-content-top {
            width: 100%;
            height: 12px;
          }

          canvas {
            // width: calc(100% - 24px);
            width: 100%;
          }
        }
      }
    }
  }
}
