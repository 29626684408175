@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.blue-button,
.red-button,
.white-button,
.outlined-button {
    height: 40px !important;
    border-radius: 28px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: row;
    border: none;
    cursor: pointer;
    // width: 100%;
    min-width: none !important;
    padding: 0 25px !important;
    transition: all 0.2s ease-in-out;

    &:not(:disabled):hover {
        opacity: 0.8;
    }

    .button-icon {
        display: inline-block;
        position: relative;
        left: -15px;
    }

    span:last-child {
        display: inline-block;
    }

    // .button-loading {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     .loading-container {
    //         position: relative;
    //         left: -27px;
    //         top: 16px;
    //         height: 50px;
    //     }

    //     span {
    //         position: relative;
    //         bottom: 13px;
    //     }
    // }

    &:disabled {
        background-color: colors.$gray-7 !important;
        cursor: auto !important;
    }
}

.blue-button {
    background-color: var(--primary-blue);
    color: #ffffff;
}

.red-button {
    background-color: colors.$red-1;
    color: #ffffff;
}

.white-button {
    background-color: white;
    color: var(--primary-blue) !important;
    border: 1px solid var(--primary-blue);

    &:disabled {
        background-color: colors.$gray-4 !important;
        border: 1px solid colors.$gray-7 !important;
        color: colors.$gray-2 !important;
    }
}

.outlined-button {
    background-color: transparent;
    color: var(--primary-blue) !important;
    border: 1px solid var(--primary-blue);

    &:disabled {
        background-color: colors.$gray-4 !important;
        border: 1px solid colors.$gray-7 !important;
        color: colors.$gray-2 !important;
        cursor: auto !important;
    }
}



.ant-btn.plain-button {
    @extend .normal-16;
    padding: 0;
    border: none;
    color: var(--primary-blue);
    background-color: transparent;
    box-shadow: none;
    outline: none;

    span {
        vertical-align: middle;
    }

    svg {
        float: left;
        color: var(--primary-blue);
        path {
            fill: var(--primary-blue);
        }
    }

    &:hover,
    &:focus {
        border: none;
        color: var(--primary-blue);
        background-color: transparent;
        box-shadow: none;
        outline: none;

        svg {
            color: var(--primary-blue);
            path {
                fill: var(--primary-blue);
            }
        }
    }
}

.outline-button.icon-button {
    margin: 0;
    min-width: 40px;
    border-radius: 4px;
    position: relative;
    color: colors.$black-0;
    transition: 0.2s all ease-in-out;
    border: 1px solid colors.$black-0;
}
.outline-button.icon-button:hover {
    opacity: 0.5;
}
