@use "../../../../../../common/styles/colors" as colors;

.plan-card {
    display: flex;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    background: rgba(0, 56, 209, 0.08);

    .name {
        font-size: 16px;

        // &:after {
        //     content: " ";
        //     left: 0;
        //     width: 16px;
        //     height: 16px;
        //     border-radius: 50%;
        //     position: absolute;
        //     border: 1px solid #000000;
        //     transform: translate(calc(-100% - 8px), 4px);
        // }
    }

    .price {
        color: var(--primary-blue);
    }

    &.card-option {
        padding: 12px 40px;
        background-color: #f2f2f2;

        .name {
            width: 100%;
            padding-bottom: 9px;
            position: relative;
            margin-bottom: 17px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }

        &.disabled {
            opacity: 0.7;
        }

        &:not(.disabled) {
            cursor: pointer;
            &:hover {
                border: 1px solid var(--primary-blue);
            }
        }
    }

    &.selected {
        border: 1px solid var(--primary-blue);

        // .name {
        //     &:after {
        //         background: #8CA5EA;
        //         border-color: var(--primary-blue);
        //     }
        // }
    }
}
