@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.profile-avatar {
  .avatar {
    img {
      width: 60px;
      height: 60px;
    }
  }
}

.circle-checked {
  svg {
    color: colors.$green-1;
  }
}

.circle-unchecked {
  svg {
    color: colors.$gray-1;
  }
}

.edit-avatar {
  .avatar {
    text-align: center;

    img {
      width: 120px;
      height: 120px;
      margin-bottom: 10px;
    }

    label {
      @extend .normal-14-500;
      color: var(--primary-blue);
    }

    input#avatar {
      display: none;
    }
  }
}

.profile-social-medias {
  .social-media > .info {
    padding: 0 10px;
  }

  .social-media:last-child .sep {
    display: none;
  }

  .favorite-icon {
    margin-right: 5px;
    position: relative;
    top: 3px;
  }
}
.flatted-phone-input {
  .ant-input-prefix {
    border: none !important;
    display: contents;
  }
}
