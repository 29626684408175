@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";

$mobile-screen: 768px;

.delete-acc-tutorial-page {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: colors.$gray-11;
  min-height: 100vh;
  padding-top: 70px;
  scroll-behavior: smooth;  

  .header-container {
    .header {
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      display: flex;
      z-index: 9999;
      padding: 0 14px;
      position: fixed;
      align-items: center;
      justify-content: space-between;
      background-color: colors.$white-1;
      box-shadow: 0 1px 9px rgba(109, 109, 109, 0.2);

      .logo {
        z-index: 1;
        width: 110px;
      }

      .blue-button {
        width: 125px;
        font-size: 12px;
      }
    }
  }

  .page-content {
    padding: 0 25px;
    padding-bottom: 80px;
  }

  .row-actions {
    padding-top: 60px;

    .ant-col {
        display: flex;
        justify-content: center;
    }

    @media (max-width: $mobile-screen) {
        .blue-button {
            width: 100% !important;
        }
    }
  }

  .ant-divider {
    margin: 0 !important;
  }

  .page-title {
    // @extend .normal-18-bold;
    font-family: Poppins;
    font-weight: 500;
    line-height: 18px;
    font-size: 24px;
    margin: 24px 0;
  }

  .subtitle {
    @extend .normal-18-bold;
    margin: 24px 0;
  }

  .info-alert {
    color: #ad3a39;
  }

  .list-steps > div {
    margin-bottom: 10px;
  }

  .list-deleting {
    margin: 15px 0;
  }

  .list-keep {
    margin: 15px 0;
  }

  // .page-congratulations {
  //     @extend .normal-18-bold;
  //     line-height: 18px;
  //     margin-bottom: 40px;
  // }

  .benefit-card {
    border-radius: 8px;
    background-color: colors.$gray-3;
    border: 1px solid colors.$gray-8;
    padding: 16px;
    max-height: 450px;

    .explain-content {
      @extend .normal-12;
      text-align: left;
      width: 100%;
      min-height: 310px;
      border-radius: 8px;
      margin-bottom: 6px;
      line-height: 18px;

      img {
        margin: 8px 0;
        height: 68px;
      }

      .benefits-list {
        margin-top: 18px;
      }

      > .ant-col:first-child {
        text-align: center;
      }
    }

    // &#payoneer {
    //     img {
    //         margin: 15px 0;
    //     }
    // }

    .action-content {
      width: 100%;
      // min-height: 80px;
      border-radius: 8px;

      .blue-button {
        width: 100%;
        height: 48px !important;
      }

      > .ant-col {
        display: flex;
        justify-content: center;
      }
    }
  }

  .footer-propozall {
    width: 100%;

    .row-logo {
      margin: 32px 0;
      padding: 0 25px;
    }

    .row-menu {
      padding: 0 25px;
      padding-bottom: 68px;

      .footer-list-title {
        @extend .normal-16-500;
      }

      .footer-menu > div {
        margin-bottom: 8px;
      }

      .footer-menu a {
        color: black;
        text-decoration: none;

        &:hover {
          color: blue;
        }
      }

      .footer-list-title {
        margin-bottom: 16px;
      }
    }

    .row-copyrigth {
      background-color: colors.$blue-0;
      width: 100%;
      height: 51px;
      color: white;
    }
  }
}
