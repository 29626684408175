@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";

.share-proposal-email {
    min-height: 350px;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    background-color: colors.$white-2;
    border: 1px #D9D9D9 solid;
    

    .ant-row.share-title {
        @extend .normal-16-500;
        padding: 20px 0;
    }

    .blue-button {
        min-width: 280px !important;
    }

    .ant-row.share-options {
        width: 100%;
        margin: 0 !important;

        .ant-col {
            width: 100%;
            display: flex;
            justify-content: center;

            .white-button {
                height: 58px !important;
                min-width: 280px !important;
                border-radius: 8px;
                font-size: 12px;
                background-color: white;
                justify-content: start;
                color: colors.$black-1 !important;
                border: 1px solid colors.$gray-7 !important;

                .button-icon {
                    margin-left: 8px;
                }
            }

            .share-btn-icon{
                &.whatsapp-icon svg {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    top: 3px;

                    path {
                        fill: var(--primary-blue) !important;
                    }
                }

                &.mail-icon svg {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    top: 3px;
                    

                    path {
                        stroke: var(--primary-blue) !important;
                    }
                }

                &.link-icon svg {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    top: 3px;
                    
                    path {
                        stroke: var(--primary-blue);
                    }
                }

                &.circle-check-icon svg {
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: 1px;

                    path {
                        fill: green;
                    }
                }
            }
        }
    }
}