@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";

.votes-satisfaction-modal {
    .title {
        font-size: 16px;
    }

    .ant-modal-body {
        padding-top: 0 !important;
    }
}

.vote-satisfaction-card {
    .ant-form {
        width: 100% !important;
    }

    .ant-row {
        width: 100%;
        padding: 0 !important;
    }

    .box-votes {
        margin-bottom: 10px;
    }
}

.image-vote-box {
    background-color: transparent;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        img {
            opacity: 0.7;
        }
    }
    
    &[data-is-active="true"] {
        background-color: #BFCDF3;
    }
}