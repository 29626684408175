@use "../../common/styles/colors"as colors;

.reports-page {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1090px;
    padding: 0 12px 20px;
    flex-direction: column;

    .list-reports {
        .list-header {
            font-size: 14px;
            padding: 0 16px;
            font-weight: 400;
            margin-top: 32px;
            margin-bottom: 24px;
        }

        .list-body {
            .list-body-card {
                height: 64px;
                font-size: 12px;
                padding: 0 16px;
                font-weight: 400;
                border-radius: 8px;
                margin-bottom: 16px;
                background-color: #FFF;

                .ant-col {
                    align-items: center;
                    display: inline-flex;
                }

                .download-button {
                    display: flex;
                    font-size: 14px;
                    cursor: default;
                    align-items: center;
                    color: var(--primary-blue);
                    justify-content: flex-start;
                    transition: all 0.2s ease-in-out;

                    &:not(.disabled) {
                        cursor: pointer;
                        &:hover {
                            opacity: 0.6;
                        }
                    }

                    .anticon {
                        font-size: 15px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .empty-card {
        padding: 0 !important;
        display: flex;
        height: 269px;
        margin-top: 24px;
        text-align: center;
        border-radius: 8px;
        align-items: center;
        color: colors.$gray-2;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2),
            0 4px 8px 0 rgba(228, 228, 228, 0.2);

        img {
            width: 152px;
        }
    }
}

.date-picker-calendar {
    width: 240px;
}