@use "../../../../common/styles/colors" as colors;

.nfse-resume-page {
    width: 100%;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1090px;
    padding: 0 0 20px;
    flex-direction: column;

    .back-button {
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 26px;
        .anticon {
            margin-right: 8px;
        }
    }

    h1 {
        margin-bottom: 32px;
    }

    .title-box {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .rouded-box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;
        padding: 32px 32px 0;
        background-color: #fff;
        justify-content: space-between;

        .junction {
            width: 49%;
            padding-bottom: 32px;

            .label-value {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }

            .value {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: var(--primary-blue);
            }

            &.selected-file {
                display: flex;
                align-items: center;
                color: var(--primary-blue);
                cursor: pointer;

                .file-icon {
                    top: 2px;
                    margin-right: 8px;
                    position: relative;
                    svg {
                        height: 40px;
                        path {
                            fill: var(--primary-blue);
                        }
                    }
                }
            }
        }
    }
}
