@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";

.proposal-accept {
  @media (max-width: 576px) {
    padding: 40px 20px !important;
  }

  @media (min-width: 576px) {
    padding: 40px 64px !important;
  }

  section {
    // min-width: 700px;
    max-width: 1090px;
    width: 100%;
  }
}
