@use "../../../../common/styles/colors" as colors;
@use "../../../../common/styles/app.scss";
@use "../ProposalResume/styles.scss";

.step-no-style {
  .proposal-create-resume {
    .main-content-top {
      display: none;
    }

    .main-content {
      padding: 0 0 20px 0 !important;
      background-color: transparent !important;

      @extend .proposal-resume;

      .row-proposal-title {
        margin: 0 !important;
        border-radius: 8px 8px 0 0 !important;

        .proposal-status {
          @media (max-width: 768px) {
            text-align: left;
          }

          @media (min-width: 768px) {
            text-align: center;
          }

          .tag-status {
            display: inline;
            color: #000 !important;
          }

          .status-icon {
            display: inline;
            padding-right: 4px;
            svg {
              width: 20px;
              height: auto;
              position: relative;
              top: 5px;
            }

            &.proposal-canceled-icon svg {
              path {
                stroke: colors.$blue-1;
              }
            }

            &.proposal-waiting_sign_contract-icon svg {
              width: 29px;
              top: 8px;
            }
          }
        }
      }

      .proposal-profile {
        .avatar {
          margin-right: 20px;

          img {
            height: 88px;
            width: 88px;
          }
        }
      }
    }

    .row-content-end {
      .blue-button,
      .white-button {
        min-width: 208px;
      }

      &[data-can-draft="false"] {
        // display: flex;
        // justify-content: flex-end;
        // width: 100%;
      }
    }
  }
}
