@use "../../../../../../common/styles/colors" as colors;

$mobile-screen: 768px;

.aside-plans {
    display: none;
    padding-bottom: 15px;

    &.open {
        top: 0;
        right: 0;
        width: 100%;
        display: block;
        position: fixed;
        z-index: 9999999;
        min-height: 100vh;
        padding: 20px 28px;
        background-color: rgba(0, 0, 0, 0.6);

        .content-upd-plan {
            top: 0;
            right: 0;
            width: 60%;
            height: 100%;
            overflow-y: auto;
            max-width: 480px;
            min-height: 100vh;
            position: absolute;
            animation-name: open;
            padding: 49px 28px;
            background-color: #fff;
            animation-duration: 0.6s;

            @media (max-width: $mobile-screen) {
                left: 0;
                padding: 24px;
                width: 100vw !important;
                max-width: none;
            }

            @keyframes open {
                from {
                    width: 0;
                }

                to {
                    width: 60%;
                }
            }

            .coupon-info {
                padding: 8px 16px;
                border-radius: 8px;
                margin-bottom: 16px;
                background: rgba(0, 56, 209, 0.08);
            }

            .top-bar-upd-plan {
                .arrow-right-icon {
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        opacity: 0.6;
                    }
                }

                .title {
                    font-size: 24px;
                    font-weight: 700;
                    margin: 22px 0 8px;

                    @media (max-width: $mobile-screen) {
                        display: inline;
                        margin: 0 0 50px 12px !important;
                        line-height: 0 !important;
                        position: relative;
                        top: -8px;
                    }
                }

                @media (max-width: $mobile-screen) {
                    padding-bottom: 24px;
                }
            }

            .warning-plan {
                display: flex;
                align-items: center;
                margin-bottom: 24px;

                .alert-icon svg {
                    width: 18px;
                    margin-right: 16px;
                    fill: colors.$yellow-0;
                }

                .text {
                    font-size: 14px;
                }
            }

            .plans {
                // padding-right: 16px;
                margin-bottom: 30px;
            }

            .blue-button {
                display: flex;
                margin: 0 auto;
                margin-bottom: 25px;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
