// blue
$blue-0: #0018e8;
$blue-1: #0038d1;
$blue-2: #0038d14f;
$blue-3: #3164f3;
$blue-4: #002999;
$blue-5: #00227e;
$blue-6: #2656d8;
$blue-7: #D9E1F8;
$blue-8: #0038D10F;

// gray
$gray-0: #525252;
$gray-1: #c2c2c2;
$gray-2: #676767;
$gray-3: #f7f7f7;
$gray-4: #d8d8d8;
$gray-11: #D9D9D9;
$gray-5: #e1e1e1;
$gray-6: #f0f0f0;
$gray-7: #a3a3a3;
$gray-8: #e0e0e0;
$gray-9: #ededed;
$gray-10: #686868;
$gray-11: #f9f9f9;

// balck
$black-0: #141414;
$black-1: #525252;
$black-2: #474747;

//red
$red-0: red;
$red-1: #cf4544;
$red-2: #ff4d4f;
$red-3: #802a2a;
$red-4: #ad3a39;
$red-5: rgba(207, 125, 124, 0.15);

//white
$white-0: #ebebeb;
$white-1: #f4f6ff;
$white-2: #fff;

//green
$green-0: #4df1a1;
$green-1: #1d945a;
$green-2: #154c40;
$green-3: #1d945a;
$green-4: #154c4030;

//yellow
$yellow-0: #f7b205;
$yellow-1: rgba(247, 178, 5, 0.15);
$yellow-2: #b28104;
