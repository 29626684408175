@use "../../styles/colors" as colors;

.tab-bar-menu {
    height: 68px;
    width: 750px;
    border-bottom: 2px solid colors.$gray-5;
    border-radius: 8px 8px 0 0;
    background-color: colors.$white-2;
    width: 100%;


    .menu {
        height: 65px;
        // margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        background-color: transparent;
        

        .menu-item {
            height: 65px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            // align-items: center;
            cursor: pointer;
            font-size: 16px;
            font-family: Poppins;
            color: colors.$gray-0;
            background-color: transparent;
            padding-top: 23px;

            .menu-item-text {
                float: left;
            }

            .style-count {
                padding: 0px 8px;
                background-color: colors.$white-1;
                border-radius: 50px;
                margin-left: 10px;
                height: 25px;
            }
            
        }
        
        .selected {
            font-weight: 500;
            color: var(--primary-blue);
            // color: colors.$blue-1;
            background-color: transparent;
            .style-count {
                background-color: colors.$blue-7;
                
        
                }
        }
    }

    .menu-marker {
        width: 100%;
        text-align: left;

        .active-marker {
            margin-bottom: -1px;
            height: 2.5px;
            // margin-top: 0px;
            // margin-left: 0px;
            // position: relative;
            // transform: translateX(0px);
            // -ms-transform: translateY(0px);
            // -webkit-transform: translateY(0px);
            // -moz-transform: translateY(0px);
            // -o-transform: translateY(0px);
            transition: margin 0.4s;
            background-color: var(--primary-blue);
            // background-color: colors.$blue-1;
        }
    }
}