@use "../../styles/colors" as colors;

.search-bar {
    background-color: colors.$gray-8;
    height: 33px;
    width: 312px;
    display: flex;
    align-items: center;
    border-radius: 16.5px;

    svg {
        margin: 17px 10px;
        color: colors.$gray-2;
        height: 14px;
    }
}
.search-input {
    outline: none;
    border: none;
    width: 250px;
    background-color: colors.$gray-8;
    font-size: 14px;
    color: colors.$gray-2;
    font-weight: 500;
}