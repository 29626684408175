@use "../../../../common/styles/colors";

.ant-form {
    .loading-message {
        padding-top: 15px;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
        text-align: center;
    }
    .password-requests {
        font-size: 14px;
        text-align: left;
        margin: 25px 0 28px;
        line-height: 24.5px;
        letter-spacing: 0.2px;

        .pr-title {
            margin: 0;
            font-weight: bold;
        }

        ul {
            padding: 0;
            list-style: none;

            li {
                display: flex;
                font-size: 14px;
                align-items: center;
                transition: all 0.2 ease-in-out;

                .circle {
                    width: 20px;
                    margin-right: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:before {
                        content: "";
                        width: 5px;
                        height: 5px;
                        display: block;
                        border-radius: 50%;
                        background-color: colors.$gray-0;
                    }
                }

                .icon {
                    display: flex;
                    min-width: 20px;
                    font-size: 12px;
                    margin-right: 8px;
                    align-items: center;
                    // color: colors.$success;
                    justify-content: center;
                }
            }
        }
    }
}


@media screen and (min-width: 768px) {
    .ant-form {
        .password-requests {
            font-size: 16px;
            margin: 32px 0 8px;

            ul {
                li {
                    font-size: 16px;

                    .circle {
                        width: 20px;
                        margin-right: 8px;

                        &:before {
                            width: 5px;
                            height: 5px;
                        }
                    }

                    .icon {
                        min-width: 20px;
                        font-size: 12px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: 1024px) {
    .ant-form {
        .password-requests {
            font-size: 18px;
            margin: 32px 0 8px;

            ul {
                li {
                    font-size: 18px;
                    margin-top: 4px;

                    .circle {
                        &:before {
                            width: 4px;
                            height: 4px;
                        }
                    }

                    .icon {
                        min-width: 20px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}