@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";

.password-container {
  min-height: 100vh;
  .left-side {
    left: 0;
    display: flex;
    position: fixed;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180.76deg, #eaeef9 11.55%, rgba(212, 241, 246, 0) 104.03%);
  }

  .right-side {
    display: flex;
    padding: 50px 0;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transform: translateX(50%);

    .subtitle {
      color: colors.$black-0;
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 27px;
      margin: 20px 0;
      text-align: center;
    }

    .forgot-password {
      color: colors.$black-0;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      cursor: pointer;
      margin-bottom: 32px;
    }

    hr {
      width: 312px;
      line-height: 1px;
      color: colors.$gray-1;
      margin-bottom: 32px;
    }

    .blue-button {
      height: 50px !important;
      margin-bottom: 15px !important;

      // .loading-container {
      //   top: 12px;
      // }
    }
  }
}
