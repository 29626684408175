@use "../../common/styles/colors"as colors;


.sort-nfse-item {
    height: 10px;

    svg {
        margin-left: 10px;
        width: 12px;
        height: 12px;
    }
}

.nfse-page {
    width: 100%;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1090px;
    padding: 0 12px 20px;
    flex-direction: column;
    position: relative;

    .export-row {
        .export-text {
            color: #141414;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // padding-left: 15px;
        }
        li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
            padding: unset !important;
            top: -5px;
            left: -2px;
        }

        .ant-dropdown-trigger {
            margin: 0;
            width: 1px;
            padding: 0;
        }

        .text-date {
            font-size: 14px;
            padding-left: 16px;
            cursor: pointer;
            border-radius: 4px;
            color: #676767;
            transition: all 0.2s ease-in-out;
        }

        .text-date:last-child {
            padding-left: 10px;
        }

        .text-date-a {
            padding-left: 10px;
        }

    }


    .container-progress-modal {
        position: fixed;
        bottom: 20px;
        right: 12px;
        max-width: 290px;
        width: 100%;
    }


    .blue-button {
        height: 44px;
        font-size: 16px;
        margin: 0 0 0 auto;

        .anticon {
            bottom: -2px;
            font-size: 20px;
            margin: 0 8px 0 0;
            position: relative;
        }
    }

    .filter-box {
        display: flex;
        line-height: 23px;
        margin: 24px 0;
        align-items: center;
        padding: 12px !important;
        justify-content: flex-start;
        background-color: colors.$white-2;

        .ant-form {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            // .ant-select-selection-item {
            //     display: flex;
            //     align-items: center;
            // }

            .row-inputs {
                width: 100%;
                padding: 0 !important;

                .ant-divider-vertical {
                    border-color: colors.$gray-9 !important;
                    height: 40px;
                    margin: 0 !important;
                    padding: 0 !important;
                }

                // Search
                .ant-input-affix-wrapper {
                    width: 100%;
                    height: 40px;
                    border-radius: 8px;
                    border: 1px solid colors.$gray-9;
                }

                .item-order {
                    margin-left: 0 !important;
                    padding-left: 0 !important;

                    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                        border: none !important;
                    }

                    .ant-select-arrow {
                        display: none;
                    }

                    .ant-form-item-label {
                        display: flex;
                        align-items: center;
                    }

                    .ant-select {
                        .ant-select-selector {
                            border: none !important;
                            padding-right: 0 !important;
                        }

                        .ant-select-selection-item {
                            display: flex;
                            align-items: center;

                            svg {
                                margin-left: 10px;
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }

                .ant-form-item {
                    margin: 0;

                    .search-bar {
                        width: 100%;
                        height: 40px;
                        border: 1px solid colors.$gray-9;
                    }

                    input {
                        width: 100%;
                        background-color: transparent;

                        &::placeholder {
                            opacity: 0.6;
                            font-size: 14px;
                            font-weight: normal;
                            color: colors.$gray-0 !important;
                        }

                        &.simple-input {
                            border: 1px solid colors.$gray-9 !important;
                        }

                    }

                    .ant-select {
                        width: 100%;

                        .ant-select-selector {
                            padding: 0 8px;
                            border-radius: 8px;
                            border: 1px solid colors.$gray-9 !important;

                            .ant-select-selection-search input {
                                height: 38px;
                            }

                            .ant-select-selection-placeholder {
                                display: flex;
                                font-size: 14px;
                                align-items: center;
                                color: colors.$gray-0 !important;
                            }
                        }
                    }
                }
            }

            .outlined-button {
                height: 40px;
                width: 100%;
                color: var(--primary-blue) !important;
                border-color: var(--primary-blue) !important;
            }
        }
    }

    .list-nfse {
        .list-header {
            font-size: 14px;
            padding: 0 16px;
            font-weight: 400;
            margin-top: 32px;
            margin-bottom: 24px;
        }

        .list-body {
            .list-body-card {
                height: 64px;
                font-size: 12px;
                padding: 0 16px;
                font-weight: 400;
                border-radius: 8px;
                margin-bottom: 16px;
                background-color: #fff;

                .ant-col {
                    align-items: center;
                    display: inline-flex;
                }

                .more-icon {
                    &.disabled {
                        opacity: 0.6;

                        svg {
                            cursor: not-allowed;
                        }
                    }

                    svg {
                        cursor: pointer;
                        transform: rotate(90deg);
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            opacity: 0.6;
                        }
                    }

                    &.ant-dropdown-open svg {
                        transform: rotate(0);
                    }
                }

                .status {
                    font-weight: 500;

                    &.status-waiting-nfs,
                    &.status-idle {
                        color: colors.$yellow-0;
                    }

                    &.status-canceled {
                        color: colors.$red-0;
                        opacity: 0.8;
                    }

                    &.status-completed {
                        color: colors.$green-1;
                    }

                    &.status-error {
                        color: colors.$red-0;
                    }
                }
            }

            .ant-row.ant-row-space-between.list-body-card:last-child {
                margin-bottom: 50px;
            }
        }
    }

    .loading-page-nfse .loading-container {
        margin-top: 50px;
    }

    .empty-card {
        padding: 0 !important;
        display: flex;
        height: 269px;
        margin-top: 24px;
        text-align: center;
        border-radius: 8px;
        align-items: center;
        color: colors.$gray-2;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        box-shadow: 0 2px 5px 5px rgba(228, 228, 228, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.2),
            0 4px 8px 0 rgba(228, 228, 228, 0.2);

        img {
            width: 152px;
        }
    }

    .container-invoice-selects {
        left: 50%;
        width: 50%;
        height: 60px;
        bottom: -100%;
        position: fixed;
        border-radius: 4px;
        transform: translate(-50%);
        transition: all 0.2s ease-in-out;
        background-color: var(--primary-blue);
        box-shadow: 0 2px 5px 5px rgba(242, 242, 242, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.12),
            0 4px 8px 0 rgba(183, 183, 183, 0.14);

        &.open {
            bottom: 32px;
        }
    }
}

ul.ant-dropdown-menu.menu-date-nfse-filter {
    background-color: transparent;
    box-shadow: none;

    li {
        background-color: transparent;
    }
}

.date-picker-calendar {
    width: 240px;
}

.text-error {
    margin-top: 26px;
}

.modal-new-nfse {
    width: 381px !important;

    .title {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 400;
        padding-top: 32px;
        margin-bottom: 24px;
        border-top: 1px solid colors.$gray-9;
    }

    .blue-button {
        width: 100%;
        height: 48px;
    }
}

// .modal-cancel-nfse {
//     width: 381px !important;

//     .ant-col {
//         width: 100%;
//     }

//     .blue-button {
//         width: 100%;
//         height: 48px;
//     }
// }



.ant-dropdown-menu-item-group-title {
    .menu-title {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
    }
}

.flatted-dropdown-input {
    border-radius: 8px;
    border: 1px solid colors.$gray-9;


    &.flatted-dropdown-input .dropdown-icon {
        line-height: 44px;
    }

    .flatted-input {
        border-radius: 8px;
        border-bottom: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 10px !important;
    }
}