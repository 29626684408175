@use "../../../../common/styles/colors" as colors;
.config-tab-content {
    
    min-height: 40vh;
    width: 1090px;
    padding: 0 12px;

    // .normal-16-500 {
    //     padding-bottom: 15px;
    //     padding-top: 15px;
    // }
    .back-page-btn {
        display: flex;
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        align-items: center;
        margin-bottom: 31px;
        color: colors.$gray-2;
        transition: all 0.2s ease-in-out;
        .arrow-right-icon {
            width: 22px;
            height: 32px;
            margin-right: 15px;
        }
        &:hover {
            opacity: 0.6;
        }
    }
    .normal-12 {
        margin-bottom: 40px ;
    }

}

.ant-divider-horizontal {
    margin: 12px 0 !important;
}
.ant-row .ant-form-item {
    margin: 0px !important;
}