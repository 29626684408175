@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.error-message {
    @extend .normal-12;
    color: colors.$red-2;

    &[data-visible="true"] {
        display: block;
    }

    &[data-visible="false"] {
        display: none;
    }
}
