.restrict-proposal-modal {
  .steps {
    width: 100%;
    text-align: right;
    margin-top: -20px;
  }

  .message-email-sent {
    color: green;
    text-align: center;
    margin-bottom: -10px;
  }
}
