@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

$mobile-screen: 768px;

.ant-modal {
    @media (max-width: $mobile-screen) {
        // top: 0 !important;
        margin: 0 !important;
        padding: 0 8px !important;
        width: 100vw !important;
        max-width: none !important;
    }
}

.ant-modal-content {
    border-radius: 8px !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    padding: 24px;

    @media (max-width: $mobile-screen) {
        // border-radius: 0 !important;
    }

    .ant-modal-close-x {
        width: 68px;
        height: 68px;
        line-height: 68px;
    }

    .ant-modal-header {
        border-radius: 8px !important;
        border-bottom: 0 !important;
        padding: 0 !important;
        @media (max-width: $mobile-screen) {
            //  border-radius: 0 !important;
        }
    }

    .ant-modal-body {
        padding: 24px 0 !important;
    }

    .ant-modal-footer {
        border: none !important;
        padding: 0 !important;
    }
}

.base-modal {
    .anticon-close {
        svg {
            color: colors.$gray-2;
            width: 14px;
            height: 14px;
        }
    }

    .feature-img {
        max-height: 184px !important;
        margin: 24px 0;
        margin-top: 30px;

        img {
            width: 100%;
            height: 184px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border: none !important;
        }
    }

    .title-icon {
        width: 48px;
        height: 48px;
        border-radius: 12px;
        text-align: center;
        line-height: 55px;
        background-color: black;
        margin-bottom: 24px;

        svg {
            color: white;
            width: 18px;
            height: 18px;

            path {
                fill: white;
            }
        }
    }

    .title {
        color: colors.$black-0;
        font-family: Poppins;
        font-size: 20px;
        letter-spacing: 0;
        font-weight: 600;
        margin: 0;
        padding-right: 25px;

        @media (max-width: $mobile-screen) {
            font-size: 18px;
        }
    }

    .subtitle-modal,
    .content-modal {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        color: colors.$gray-0;
    }

    .right-btn,
    .left-btn {
        button {
            width: 100%;
            height: 44px !important;
        }
    }

    .right-btn {
        button {
            color: white !important;
        }
    }

    .left-btn {
        button {
            color: colors.$black-0 !important;
            border-color: colors.$gray-7 !important;
        }
    }

    .actions-base-modal {
        @media (max-width: $mobile-screen) {
            flex-direction: column-reverse;
        }
    }
}

.base-modal-general {
    .title-icon {
        display: none;
    }

    .right-btn {
        button {
            background-color: var(--primary-blue);
        }
    }
}

.base-modal-alert {
    .title-icon {
        background-color: colors.$yellow-0;

        svg {
            width: 24px;
            height: 24px;
            color: colors.$black-0;
            position: relative;
            top: 3px;
            path {
                stroke: colors.$black-0;
                fill: transparent;
            }
        }
    }

    .right-btn {
        button {
            background-color: colors.$yellow-0;
            color: colors.$black-0 !important;
        }
    }
}

.base-modal-error {
    .title-icon {
        background-color: colors.$red-4;
        svg {
            width: 24px;
            height: 24px;
            position: relative;
            top: 3px;
        }
    }

    .right-btn {
        button {
            background-color: colors.$red-4;
        }
    }
}

.base-modal-success {
    .title-icon {
        background-color: colors.$green-3;
        svg {
            width: 24px;
            height: 24px;
        }
    }

    .right-btn {
        button {
            background-color: colors.$green-3;
        }
    }
}
