@use "../../common/styles/colors" as colors;

.contract-view-page {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1090px;
    padding: 0 12px 20px;
    flex-direction: column;

    .back-page-btn {
        display: flex;
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        align-items: center;
        margin-bottom: 19px;
        color: colors.$gray-2;
        transition: all 0.2s ease-in-out;
        .arrow-right-icon {
            width: 20px;
            height: 32px;
            margin-right: 15px;
        }
        &:hover {
            opacity: 0.6;
        }
    }

    .contract-info-bar {
        padding: 18px 24px;
        border-radius: 4px !important;
        margin-bottom: 16px;
        background-color: colors.$white-2;
    }

    .contract-info-bar .counterparty-info {
        .avatar-src {
            width: 45px;
            height: 45px;
        }

        span {
            font-size: 24px;
            font-weight: 500;
            margin-left: 22px;
        }
    }

    .contract-info,
    .contract-events {
        margin: 30px 0;

        svg {
            cursor: pointer;
            width: 18px;
            height: auto;
            color: colors.$gray-2;

            path {
                fill: colors.$gray-2;
            }

            &:hover {
                opacity: 0.6;
            }
        }

        .show-icon svg {
            width: 22px;
        }
    }

    .signers {
        // margin: 118px 0 40px;
        .simple-table {
            padding: 18px 24px;
            border-radius: 4px;
            margin-bottom: 16px;
            background-color: colors.$white-2;

            .ant-col {
                display: flex;
                align-items: center;
               
                > div {
                    width: 100%;
                    display: block;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    overflow: hidden;
                    max-height: 45px; // 2 linhas
                }

                .show-icon,
                .anticon-download {
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        opacity: 0.6;
                    }
                }

                .show-icon {
                    height: 28px;
                    font-size: 24px;
                }

                .anticon-download {
                    height: 18px;
                    font-size: 20px;
                }
            }
        }
    }

    .title-events {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 17px;
    }

    .events {
        .ant-row {
            margin-bottom: 16px;
        }
    }
}
