@use "../../common/styles/colors" as colors;

.empty {
    padding: 0 !important;
    display: flex;
    height: 269px;
    margin-top: 24px;
    text-align: center;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center !important;

    img {
        width: 152px;
    }
}
#button-config-btn,
#button-config-blue-btn {
    .bell-config-icon {
        svg {
            width: 20px;
            // position: relative;
            // left: -6px;
            // top: 3px;
        }
    }
}

#button-config-btn {
    background-color: transparent;
    color: var(--primary-blue);
}
#button-config-blue-btn {
    background-color: var(--primary-blue);
    color: white;
    .bell-config-icon {
        color: white;
        path {
            color: white;
            stroke: white;
            &:nth-child(3) {
                fill: blue;
            }
        }
    }
}
