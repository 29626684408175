.container-proposal-tag {
  width: auto;
  margin: 2px 0;
  max-width: 80px;
  max-height: 19px;
  padding: 0 8px;
  font-size: 11px;
  // color: #222222;
  // border: 1px solid;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
