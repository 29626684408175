@use "../../styles/colors" as colors;
@use "../../styles/app.scss";

.help-info {
  border-radius: 8px;
  background-color: #ffffff;
  min-width: 250px;
  padding: 12px;

  &.floating {
    position: absolute;
    box-shadow: 0 2px 5px 5px rgba(242, 242, 242, 0.2), 0 1px 10px 0 rgba(228, 228, 228, 0.12),
      0 4px 8px 0 rgba(183, 183, 183, 0.14);
  }

  .title,
  .text {
    padding-top: 10px 0;
    text-align: center;
    p {
      width: 100%;
    }
  }

  .title {
    @extend .normal-14-500;
  }

  .close-icon {
    svg {
      width: 15px;
      height: auto;

      path {
        fill: colors.$gray-2;
      }
    }
  }
}

.circle-help-icon {
  cursor: pointer;

  svg {
    width: 20px;
    height: auto;

    path {
      fill: colors.$gray-2;
    }
  }
}
