@use "../../styles/colors"as colors;


.steps-status {
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  background-color: colors.$white-2;
  border-radius: 8px; 
  border: 1px #D9D9D9 solid;
  width: 100%;

  .ant-progress {
    .ant-progress-bg {
      background-color: colors.$blue-1;
    }

    // .ant-progress-text {
    //   display: none;
    // }
  }
}