@use "../../common/styles/colors" as colors;
@use "../../common/styles/app.scss";
@use "../proposal/components/ProposalResume/styles.scss";

.top-bar {
  width: 100%;
  height: 90px;
  background-color: white;
}

body {
  background-color: colors.$gray-3 !important;

  .body-container {
    .content {
      width: 100%;
    }
  }
}

.proposal-invoice {
  min-width: 700px;
  max-width: 1090px;
  width: 100%;

  .main-content-top {
    height: 12px;
    width: 100%;
    background-color: var(--primary-blue);
    border-radius: 8px 8px 0 0;
  }

  .blue-button {
    min-width: 180px;
    // height: 40px;
  }

  .main-content {
    padding: 0 0 20px 0 !important;
    background-color: #fff !important;

    @extend .proposal-resume;

    .row-proposal-title {
      margin: 0 !important;
      padding: 8px !important;

      .proposal-title {
        // padding-right: 20px;
      }

      .proposal-status-col {
        @media (max-width: 768px) {
          text-align: left;
        }

        @media (min-width: 768px) {
          text-align: center;
        }
      }

      .created-at {
        // line-height: 5px;
        @media (max-width: 768px) {
          text-align: left;
        }

        @media (min-width: 768px) {
          text-align: right;
        }

        span {
          padding-left: 8px;
          cursor: pointer;
          position: relative;
          top: 4px;

          svg {
            height: 22px;
            width: auto;
            color: colors.$gray-2;

            path {
              fill: colors.$gray-2 !important;
            }
          }
        }
      }
    }

    // > .ant-row {
    //   &.title {
    //     @extend .normal-20;
    //     background-color: colors.$gray-5;
    //     padding: 8px 32px !important;
    //     margin: 0;
    //   }

    //   &.content {
    //     padding: 20px 32px !important;
    //     margin: 0;

    //     .label-value {
    //       padding-bottom: 15px;
    //     }

    //     .label-value:last-child {
    //       padding-bottom: 0;
    //     }
    //   }
    // }

    .invoice-file {
      @extend .normal-14;
    }

    .upload-file {
      margin-top: 5px;
      @extend .normal-14;
      color: var(--primary-blue);

      input#invoice {
        display: none;
      }

      .upload-icon {
        margin-left: 10px;
        position: relative;
        top: 3px;

        svg {
          width: 20px;
          height: auto;

          path {
            fill: var(--primary-blue) !important;
          }
        }
      }
    }

    .label-value {
      label {
        @extend .normal-12;
        color: colors.$gray-2;
      }

      div {
        @extend .normal-14-500;
        color: colors.$black-0;
      }
    }

    .proposal-title {
      line-height: 56px;

      > div:nth-child(2) {
        display: flex;

        span {
          padding-left: 10px;
          position: relative;
          top: 4px;

          svg {
            height: 22px;
            width: auto;
            color: colors.$gray-2;

            path {
              fill: colors.$gray-2 !important;
            }
          }
        }
      }
    }

    .proposal-profile {
      > div:first-child {
        display: flex;

        .avatar {
          margin-right: 20px;

          img {
            width: 80px;
            height: auto;
          }
        }
      }

      .address {
        height: 88px;
      }
    }
  }
}
